
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class LabelPieChart extends Vue {
  @Prop({ default: false })
  public metrics!: IMetrics;
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public predictions!: any;
  @Prop({ default: false })
  public labelsByIds!: any;
  @Prop({ default: false })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;

  public handlePlotClick(data) {
    const chosenLabel = this.labels.filter((label) => label.name === data.points[0].label);
    this.handleclick(chosenLabel[0].id);
  }

  get labelCountList() {
    const res: number[] = [];
    this.labels.forEach((k) => {
      res.push(k.counter);
    });
    return res;
  }
  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelManualColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }
  get predictionCountList() {
    const res: number[] = [];

    this.labels.forEach((v, k) => {
      res.push(this.predictions[v.id]);
    });
    return res;
  }

  get bothCountList() {
    const res: number[] = [];

    this.labels.forEach((v, k) => {
      res.push(this.predictions[v.id] + v.counter);
    });
    return res;
  }

  get labelBothBarData() {
    return [
      {
        values: this.bothCountList,
        labels: this.labelNames,
        type: "pie",

        marker: {
          colors: this.labelManualColorList,
        },
        textposition: "inside",
      },
    ];
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.labels.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k.id);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }

  get annotations() {
    const annotationList: any[] = [];
    if (this.metrics.keys.length <= 30) {
      for (let i = this.metrics.cm.length - 1; i >= 0; i--) {
        for (let j = this.metrics.cm[i].length - 1; j >= 0; j--) {
          const currentValue = this.metrics.cm[i][j];
          const result = {
            xref: "x1",
            yref: "y1",
            x: j,
            y: i,
            text: currentValue,
            font: {
              family: "Arial",
              size: 12,
              color: "white",
            },
            showarrow: false,
          };
          annotationList.push(result);
        }
      }
    }

    return annotationList;
  }

  get texts() {
    let annotationList: any[][] = [];
    this.metrics.cm.forEach((dim0Value, dim0) => {
      let text: any[] = [];
      dim0Value.forEach((dim1Value, dim1) => {
        text = [
          ...text,
          `Predicted: ${this.labelNames[dim0Value.length - dim1 - 1]} <br />Actual: ${
            this.labelNames[dim0]
          } <br />Count: ${dim1Value}`,
        ];
      });
      annotationList = [...annotationList, text];
    });
    return annotationList;
  }

  @Emit()
  public handleclick(predictedLabelId) {}
}
