
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
  IColumnInformation,
  IModelFilterString,
  IModelFilterTimestamp,
  IModelFilterLabel,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetOriginal,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readDatasets, readDataset } from "@/store/dataset/getters";

import {
  readToken,
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
} from "@/store/main/getters";
import { api } from "@/api";
import dayjs from "dayjs";
import colors from "vuetify/es5/util/colors";

import { ISubscription } from "@/interfaces";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ModelCard from "@/components/ModelCard.vue";
import TimeSeries from "@/components/TimeSeries.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import TimeBarChart from "@/components/TimeBarChart.vue";
import CSATBarChart from "@/components/CSATBarChart.vue";
import TimeTimeSeries from "@/components/TimeTimeSeries.vue";
import TimeTimeSeriesStacked from "@/components/TimeTimeSeriesStacked.vue";
import MiniBarChart from "@/components/MiniBarChart.vue";
import TimeGraphSettings from "@/components/TimeGraphSettings.vue";
import SubCategoryPicker from "@/components/SubCategoryPicker.vue";

import { startOfWeek, endOfWeek, addWeeks, format, getWeek, getYear } from "date-fns";

@Component({
  components: {
    MiniBarChart,
    TimeTimeSeries,
    TimeTimeSeriesStacked,
    TimeBarChart,
    LottieAnimation,
    ItemPreview,
    CSATBarChart,
    TimeSeries,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
    TimeGraphSettings,
    SubCategoryPicker,
  },
})
export default class TimeGraphView extends Vue {
  public menu: boolean = false;
  public showplease: boolean = true;
  public loading: boolean = false;
  public columns: any[] = [];
  public rows: any[] = [];
  public rowsToShow: any[] = [];
  public dataGraph: any = false;
  public graphError: string = "";
  public subscribedToPlan: ISubscription = {} as ISubscription;
  public SubkeyToShow: string = "countOfLabel";
  public keyToShow: string = "countOfLabel";
  public paginationTrendingVolume: any = { rowsPerPage: 4 };
  public paginationTrending: any = { rowsPerPage: 4 };
  public paginationCategory: any = { rowsPerPage: 4 };
  public paginationColumn: any = { rowsPerPage: 4 };
  public paginationCategoryColumn: any = { rowsPerPage: 4 };
  public paginationHistoricalMeanSpent: any = { rowsPerPage: 4 };
  public paginationHistoricalMeanSpentColumn: any = { rowsPerPage: 4 };

  public endDate: any = "";
  public startDate: any = "";
  public menuEnd: boolean = false;
  public menuStart: boolean = false;
  public column: any = -1;

  public tabModel: number = 0;
  public running: boolean = false;
  public time: string = "minutes";
  public connections: any = [];
  public historicalKey: string = "meanSpent";
  public historicalKeyColumn: string = "countOfLabel";
  public decimalPoints: number = 0;

  public recommendation: number = -1;
  public pickSubCategoryDialog: boolean = false;

  public dialogFilter: boolean = false;
  public d6: number = 0;
  public recommendationFilter: number = -1;
  public filterError: any = null;
  public filterType: string = "must";
  public columnInformation: IColumnInformation = {} as IColumnInformation;
  public pickedValues: any[] = [];
  public showAll: boolean = false;
  public totalItems: number = 0;
  public modelFilters: any = [];
  public expandedRow: number | null = null;
  public loadingButton: boolean = false;
  public connectedModels: any[] = [];
  public tempModelFilters: any = [];
  public expandedRowModel: number | null = null;

  public filterDataset: boolean = true;
  public pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
  public dateBucketType: string = "month";

  public aggregateConversations: boolean = true;

  public paginationDatesPreview: any = { rowsPerPage: 6 };

  public selectedDateZoomIn: string = "all";

  public pendingUpdates: any = [];
  public graphSettingsKey: number = 0;
  public subCategoryKey: number = 3;
  public finalModelFilters: any = [];

  public subKeyTranslator = {
    Volume: "countOfLabel",
    "Mean agent workload": "meanSpent",
    "Mean response time": "meanResponse",
    "Mean customer workload": "meanResolve",
    "Total response time": "totalResponse",
    "Total customer workload": "totalResolve",
    "Total agent workload": "totalSpent",
  };

  public keyTranslator = {
    Volume: "countOfLabel",
    "Mean agent workload": "meanSpent",
    "Mean response time": "meanResponse",
    "Mean customer workload": "meanResolve",
    "Total response time": "totalResponse",
    "Total customer workload": "totalResolve",
    "Total agent workload": "totalSpent",
  };

  public historicalTranslator = {
    "Mean agent workload": "meanSpent",
    "Mean response time": "meanResponse",
    "Mean customer workload": "meanResolve",
  };
  public historicalTranslatorColumn = {
    Volume: "countOfLabel",
    "Weighted volume": "countOfLabelDiff",
    "Mean agent workload": "meanSpent",
    "Mean response time": "meanResponse",
    "Mean customer workload": "meanResolve",
  };

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public updateKeys() {
    this.graphSettingsKey = this.graphSettingsKey + 1;
    this.subCategoryKey = this.subCategoryKey + 3;
    this.pendingUpdates = [];
    this.modelFilters = this.finalModelFilters.slice();
  }

  public async applyPendingUpdates() {
    this.pendingUpdates.forEach((action) => {
      if ("changeSubCategory" in action) {
        this.column = action.changeSubCategory.column;
      }
      if ("changeAggregate" in action) {
        this.selectedDateZoomIn = "all";
        this.aggregateConversations = action.changeAggregate.aggregateConversations;
      }
      if ("changeDateBucket" in action) {
        this.selectedDateZoomIn = "all";
        this.dateBucketType = action.changeDateBucket.dateBucketType;
      }
    });
    this.pendingUpdates = [];

    if (!this.arraysContainSameObjects(this.finalModelFilters, this.modelFilters)) {
      this.finalModelFilters = this.modelFilters.slice();
    }
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time?column=${this.column}&filters=${this.encodeFilters}&datebucket=${this.dateBucketType}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
    );
  }

  public arraysContainSameObjects<T>(a: T[], b: T[]): boolean {
    // Check if both arrays have the same length
    if (a.length !== b.length) {
      return false;
    }

    // Convert objects to strings for comparison
    const aStrings = a.map((item) => JSON.stringify(item));
    const bStrings = b.map((item) => JSON.stringify(item));

    // Check if every object in 'a' is present in 'b' and vice versa
    return (
      aStrings.every((item) => bStrings.includes(item)) &&
      bStrings.every((item) => aStrings.includes(item))
    );
  }

  //saves updates for all settings and are then applied.
  public async createPendingUpdates(action) {
    if ("changeSubCategory" in action) {
      // Find the index of the existing action with the key 'changeSubCategory'
      const index = this.pendingUpdates.findIndex((item) => "changeSubCategory" in item);

      if (index !== -1) {
        // If the action already exists, replace it with the new action
        this.pendingUpdates.splice(index, 1, action);
      } else {
        // If the action does not exist, push the new action
        this.pendingUpdates.push(action);
      }
    }
    if ("changeAggregate" in action) {
      // Find the index of the existing action with the key 'changeAggregate'
      const index = this.pendingUpdates.findIndex((item) => "changeAggregate" in item);

      if (index !== -1) {
        // If the action already exists, replace it with the new action
        this.pendingUpdates.splice(index, 1, action);
      } else {
        // If the action does not exist, push the new action
        this.pendingUpdates.push(action);
      }
    }
    if ("changeDateBucket" in action) {
      // Find the index of the existing action with the key 'changeDateBucket'
      const index = this.pendingUpdates.findIndex((item) => "changeDateBucket" in item);

      if (index !== -1) {
        // If the action already exists, replace it with the new action
        this.pendingUpdates.splice(index, 1, action);
      } else {
        // If the action does not exist, push the new action
        this.pendingUpdates.push(action);
      }
    }
    if ("filter" in action) {
      if (this.arraysContainSameObjects(this.finalModelFilters, this.modelFilters)) {
        // Find the index of the existing 'filter' action in pendingUpdates
        const filterIndex = this.pendingUpdates.findIndex(
          (pendingAction) => "filter" in pendingAction,
        );

        // If a 'filter' action exists, remove it from pendingUpdates
        if (filterIndex !== -1) {
          this.pendingUpdates.splice(filterIndex, 1);
        }
      } else {
        // Check if a 'filter' action already exists in pendingUpdates
        const filterIndex = this.pendingUpdates.findIndex(
          (pendingAction) => "filter" in pendingAction,
        );

        // If a 'filter' action does not exist, add the new action to pendingUpdates
        if (filterIndex === -1) {
          this.pendingUpdates.push(action);
        } else {
          // If a 'filter' action already exists, you might want to update it instead of adding a new one
          // This depends on your application logic
          this.pendingUpdates[filterIndex] = action;
        }
      }
    }
  }

  public async selectDateZoomIn(selectDate: string) {
    this.selectedDateZoomIn = selectDate;
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time?column=${this.column}&filters=${this.encodeFilters}&datebucket=${this.dateBucketType}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
    );
  }

  get reversedTimeStamps() {
    const reversed: any[] = [...this.allTimeStamps].reverse();
    reversed.unshift("all");
    return reversed;
  }

  public switchAggregate(aggregate: boolean) {
    const action = {
      changeAggregate: { selectedDateZoomIn: "all", aggregateConversations: aggregate },
    };

    this.createPendingUpdates(action);
  }

  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );

      if (conversationColumn) {
        // console.log('Found conversation column:', conversationColumn);
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public switchDateBucket(value: string) {
    const action = {
      changeDateBucket: { dateBucketType: value, selectedDateZoomIn: "all" },
    };

    this.createPendingUpdates(action);
  }

  public formatDate(dateString: string) {
    const date = new Date(dateString);
    if (this.selectedDateZoomIn !== "all") {
      // If we have selected a specific date with zoom in, we format as "yyyy-MM-dd"
      return format(date, "yyyy-MM-dd");
    } else {
      if (this.dateBucketType === "month") {
        // Format as "yyyy-MM"
        return format(date, "yyyy-MM");
      } else if (this.dateBucketType === "week") {
        // Format as "yyyy-WeekNumber"
        return format(date, "yyyy-MM-dd");
      } else {
        // If dateBucketType is neither 'month' nor 'week', return the original date string
        return dateString;
      }
    }
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  get getModelAndLabels() {
    if (this.pickedModelAndLabels.model === -1) {
      return {
        modelName: "unknown",
        labels: [],
      };
    }

    const pickedModel = this.connectedModels.find(
      (model) => model.id === this.pickedModelAndLabels.model,
    );

    if (!pickedModel) {
      throw new Error(`Model with id ${this.pickedModelAndLabels.model} not found`);
    }

    const labels = pickedModel.label_containers
      .flatMap((container) => container.labels)
      .filter((label) => this.pickedModelAndLabels.labels.includes(label.id));

    return {
      modelName: pickedModel.name,
      labels,
    };
  }

  public doesFilterAlreadyExist(column, modelId) {
    return this.tempModelFilters.some(
      (filter: any) => filter.column_id === column && filter.inherited_from === modelId,
    );
  }

  public createTempModelFilters() {
    this.tempModelFilters = this.modelFilters.slice();

    const filter: IModelFilterLabel = {
      filter_type: "label",
      label_id: this.pickedModelAndLabels.labels,
      inherited_from: this.pickedModelAndLabels.model,
      label_container: this.pickedModelAndLabels.label_container,
    };

    this.tempModelFilters.push(filter);
  }

  public inherit(filter: any) {
    this.tempModelFilters.push(filter);
  }

  get getModelFiltersOfPickedModel() {
    const modelo = this.connectedModels.find((m) => m.id === this.pickedModelAndLabels.model);

    // If a matching model is found, return its filters
    if (modelo) {
      console.log(modelo.filters);
      const datasetModelFilter = modelo.filters.filters.find(
        (m) => m.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
      if (datasetModelFilter) {
        return datasetModelFilter.filters;
      }
      return [];
    }
    // If no matching model is found, return []
    return [];
  }

  public deleteTempFilter(index: number) {
    this.tempModelFilters.splice(index, 1);
  }

  public pickModelAndLabel(labelId: number, modelId: number, labelContainerId: number) {
    this.pickedModelAndLabels.model = modelId;
    this.pickedModelAndLabels.labels = [labelId];
    this.pickedModelAndLabels.label_container = labelContainerId;
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.connectedModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public getModelById(id: number) {
    return this.connectedModels.find((m) => m.id === id);
  }

  get encodeFilters() {
    const filtersString = JSON.stringify(this.finalModelFilters);
    return btoa(filtersString);
  }

  public applyFilter() {
    this.dialogFilter = false;

    const action = {
      filter: true,
    };

    this.createPendingUpdates(action);
  }

  public expandRow(index) {
    console.log("index", index);
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }

  public createCombined(data) {
    let preDefinedFilters: any[] = [];
    let datasetFilter;
    if (this.model && this.model.filters && this.model.filters.filters) {
      datasetFilter = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    if (datasetFilter && datasetFilter.filters.length > 0) {
      preDefinedFilters = datasetFilter.filters.map((item) => ({
        ...item,
        on_model: true,
      }));
    }
    const pickedFilters: any[] = data.map((item) => ({
      ...item,
      combined: item.column_id ? item.column_id : item.inherited_from,
      on_model: false,
    }));

    return [...pickedFilters, ...preDefinedFilters];
  }

  public deleteFilter(index: number) {
    this.modelFilters.splice(index, 1);
    this.applyFilter();
  }

  public getFilterColumnName(id) {
    if (this.rows.length > 0) {
      const columnIndex = `column_${id}`;
      const row = this.rows[0];
      if (row.hasOwnProperty(columnIndex)) {
        return row[columnIndex];
      }
    } else {
      return "None";
    }
  }

  public toggleFilter() {
    const allPresentInPickedValues = this.columnInformation.column_values.every((obj) =>
      this.pickedValues.includes(obj.value),
    );
    if (!allPresentInPickedValues) {
      this.columnInformation.column_values.forEach((obj) => {
        if (!this.pickedValues.includes(obj.value)) {
          this.pickedValues.push(obj.value);
        }
      });
    } else {
      this.pickedValues = [];
    }
  }

  public async getFilterCount(datasetFilter = true) {
    this.loadingButton = true;
    this.filterError = null;
    let allFilters = this.modelFilters.slice();
    if (datasetFilter === true) {
      if (this.filterType !== "timestamp") {
        const filter: IModelFilterString = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          filter_values: this.pickedValues,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
        };

        allFilters.push(filter);
      } else {
        const filter: IModelFilterTimestamp = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
          start_date: this.startDate,
          end_date: this.endDate,
        };

        allFilters.push(filter);
      }
    } else {
      allFilters = this.tempModelFilters.slice();
    }

    // Add the pre-existing model filters
    let preExistingFilters;
    if (this.model && this.model.filters && this.model.filters.filters) {
      preExistingFilters = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    if (preExistingFilters && preExistingFilters.filters.length > 0) {
      allFilters.push(...preExistingFilters.filters);
    }

    await api
      .getFilterCount(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        allFilters,
      )
      .then((r) => {
        this.loadingButton = false;
        this.totalItems = r.data.total_items;
        this.d6 = 3;
      })
      .catch((error) => {
        console.log("filterError", error.response);
        this.loadingButton = false;
        this.filterError = error.response;
      });
  }

  public async filterColumnValues() {
    this.loadingButton = true;
    this.filterError = null;

    const allFilters = this.modelFilters.slice();

    // Add the pre-existing model filters
    let preExistingFilters;
    if (this.model && this.model.filters && this.model.filters.filters) {
      preExistingFilters = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    if (preExistingFilters && preExistingFilters.filters.length > 0) {
      allFilters.push(...preExistingFilters.filters);
    }

    console.log("OOOK", this.recommendationFilter);

    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.recommendationFilter,
        allFilters,
      )
      .then((r) => {
        this.columnInformation = r.data;
        this.loadingButton = false;
        this.d6 = 2;
        if (this.columnInformation.dtype === "timestamp") {
          this.filterType = "timestamp";
        } else {
          this.filterType = "must";
        }
        console.log("kolla", r.data, typeof this.columnInformation);
      })
      .catch((error) => {
        console.log("filterError", error.response);
        this.loadingButton = false;
        this.filterError = error.response;
        this.columnInformation = {} as IColumnInformation;
      });
  }

  public addModelFilter() {
    if (this.filterType !== "timestamp") {
      const filter: IModelFilterString = {
        column_id: parseInt(this.columnInformation.column_id, 10),
        store_column_name: this.columnInformation.store_column_name,
        filter_values: this.pickedValues,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
      };
      this.modelFilters.push(filter);
    } else {
      const filter: IModelFilterTimestamp = {
        column_id: parseInt(this.columnInformation.column_id, 10),
        store_column_name: this.columnInformation.store_column_name,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      this.modelFilters.push(filter);
    }
  }

  public goBackFilter() {
    if (this.filterDataset) {
      if (this.d6 === 1) {
        this.d6 = 0;
      } else if (this.d6 === 2) {
        this.d6 = 1;
        this.pickedValues = [];
        this.startDate = "";
        this.endDate = "";
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    } else {
      if (this.d6 === 1) {
        this.d6 = 0;
        this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
      } else if (this.d6 === 2) {
        this.tempModelFilters = [];
        this.expandedRowModel = null;
        this.d6 = 1;
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    }
  }

  public nextStepFilter() {
    console.log(this.d6);
    if (this.filterDataset) {
      if (this.d6 === 1) {
        this.filterColumnValues();
      } else if (this.d6 === 2) {
        this.getFilterCount();
      } else if (this.d6 === 3) {
        this.addModelFilter();
        this.applyFilter();
        this.closeFilterDialog();
      }
    } else {
      if (this.d6 === 0) {
        this.d6 = 1;
      } else if (this.d6 === 1) {
        this.d6 = 2;
        this.createTempModelFilters();
      } else if (this.d6 === 2) {
        this.getFilterCount(false);
      } else if (this.d6 === 3) {
        this.modelFilters = this.tempModelFilters.slice();
        this.applyFilter();
        this.closeFilterDialog();
      }
    }
  }

  public closeFilterDialog() {
    this.dialogFilter = false;
    this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
    this.d6 = 0;
    this.recommendationFilter = -1;
    this.filterDataset = true;
    this.filterError = null;
    this.filterType = "must";
    this.columnInformation = {} as IColumnInformation;
    this.pickedValues = [];
    this.showAll = false;
    this.endDate = "";
    this.startDate = "";
    this.tempModelFilters = [];
    this.expandedRowModel = null;
  }

  get activeRecommendationFilter() {
    if (this.recommendationFilter > -1) {
      return this.columns[this.recommendationFilter].value;
    } else {
      return "None";
    }
  }

  public isThisTextColumn(key: number) {
    const datasetWithMetaData = this.datasets.filter(
      (dataset) => dataset.id === parseInt(this.$router.currentRoute.params.id, 10),
    )[0];
    const keyString = String(key); // Convert the key to a string

    if (datasetWithMetaData.meta_data.columns.hasOwnProperty(keyString)) {
      const column = datasetWithMetaData.meta_data.columns[keyString];

      if (column.hasOwnProperty("special_column") && column.special_column === "text") {
        console.log(`Column ${keyString} has special_column = "text"`);
        return true;
      }
    }

    return false;
  }

  public highlightFilter(key, value) {
    let newColumn = this.recommendationFilter;

    // checks model filters
    let datasetFilter;
    if (this.model && this.model.filters && this.model.filters.filters) {
      datasetFilter = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    let alreadyFilter: boolean = false;
    if (
      datasetFilter &&
      datasetFilter.filters.length > 0 &&
      datasetFilter.filters.find((item) => item.column_id === key)
    ) {
      alreadyFilter = true;
    }

    // Checks column and added filters
    if (Number(key) || Number(key) === 0) {
      if (
        !this.isThisTextColumn(key) &&
        this.modelFilters.filter((filter) => filter.column_id === key).length === 0 &&
        !alreadyFilter
      ) {
        newColumn = Number(key);
      }
    }

    if (this.recommendationFilter === newColumn) {
      this.recommendationFilter = -1;
    } else {
      this.recommendationFilter = newColumn;
    }
  }

  public async beforeRouteUpdate(to, from, next) {
    this.chooseDataset();
    this.getGraphData();
    next();
  }

  public changeColumn(column) {
    console.log("COLUMN");
    this.pickSubCategoryDialog = false;
    //this.column = column;
    this.recommendation = -1;

    const action = {
      changeSubCategory: { column: column },
    };

    this.createPendingUpdates(action);
  }

  public async chooseDataset() {
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        true,
      )
      .then((r) => {
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        // this.headDialog = true;
        this.rowsToShow = this.rows;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  get getColumnName() {
    if (this.column === -1) {
      return "Main clusters";
    } else if (this.column === -2) {
      return "Sub clusters";
    } else if (this.rows.length > 0) {
      const columnIndex = `column_${this.column}`;
      const row = this.rows[0];
      if (row.hasOwnProperty(columnIndex)) {
        return row[columnIndex];
      }
    } else {
      return "None";
    }
  }

  // public highlight(key, value) {
  //   const newColumn = Number(key) || -1;
  //   this.recommendation = newColumn;
  // }

  // get activeRecommendation() {
  //   if (this.recommendation > -1) {
  //     return this.columns[this.recommendation].value;
  //   } else {
  //     return "None";
  //   }
  // }

  public hasProperty(specialColumnValue: string) {
    let hasValue = false;

    for (const key in this.dataset!.meta_data.columns) {
      if (this.dataset!.meta_data.columns.hasOwnProperty(key)) {
        const column = this.dataset!.meta_data.columns[key];
        if (column.special_column === specialColumnValue) {
          hasValue = true;
          break;
        }
      }
    }
    return hasValue;
  }

  public launchGraphCheck(graphType: string) {
    if (graphType === "csat" && this.hasProperty("date") && this.hasProperty("csat")) {
      this.$router
        .push(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/csat`,
        )
        .catch(() => {});
    } else if (
      graphType === "time" &&
      this.hasProperty("date") &&
      this.hasProperty("response_time") &&
      this.hasProperty("resolve_time")
    ) {
      this.$router
        .push(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time`,
        )
        .catch(() => {});
    } else {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/setup?setup_type=${graphType}&redirect=csat`,
      );
      console.log("none");
    }
  }

  public historicalCategory(tag) {
    const result = Object.entries(this.dataGraph.historicalCategory.YearMonth)
      .map(([id, period]) => ({
        period: period as any,
        countOfLabelDiff: this.dataGraph.historicalCategory.countOfLabel_diff[id.toString()],
        categoryName: this.dataGraph.historicalCategory["label text"][id.toString()],
        meanSpent: this.dataGraph.historicalCategory[tag][id.toString()],
        meanSpent_diff: this.dataGraph.historicalCategory[tag + "_diff"][id.toString()],
        meanSpentPctDiff: this.dataGraph.historicalCategory[tag + "_pct_diff"][id.toString()],
      }))
      .filter((obj) => obj.meanSpent_diff !== 0 && obj.countOfLabelDiff !== 0)
      .sort(
        (a, b) =>
          Math.abs(b[tag + "PctDiff"] * b.countOfLabelDiff) -
          Math.abs(a[tag + "PctDiff"] * a.countOfLabelDiff),
      );
    return result;
  }

  public historicalColumn(tag) {
    const result = Object.entries(this.dataGraph.historicalColumn.YearMonth)
      .map(([id, period]) => {
        // Base object with properties that are always included
        const baseObject = {
          period: period as any,
          countOfLabelDiff: this.dataGraph.historicalColumn.countOfLabel_diff[id.toString()],
          countOfLabel: this.dataGraph.historicalColumn.countOfLabel[id.toString()],
          countOfLabelPctDiff: this.dataGraph.historicalColumn.countOfLabel_pct_diff[id.toString()],
          name: this.dataGraph.historicalColumn.chosenColumn[id.toString()],
        };

        // Conditional properties based on the value of 'tag'
        const conditionalProperties =
          tag !== "countOfLabel" && tag !== "countOfLabelDiff"
            ? {
                meanSpent: this.dataGraph.historicalColumn[tag][id.toString()],
                meanSpent_diff: this.dataGraph.historicalColumn[tag + "_diff"][id.toString()],
                meanSpentPctDiff: this.dataGraph.historicalColumn[tag + "_pct_diff"][id.toString()],
              }
            : {};

        // Combine the base object with the conditional properties
        return {
          ...baseObject,
          ...conditionalProperties,
        };
      })
      .filter((obj) => {
        // Apply filter based on the value of 'tag'
        if (obj.name !== "Not assigned") {
          if (tag !== "countOfLabel" && tag !== "countOfLabelDiff") {
            return obj.meanSpent_diff !== 0 && obj.countOfLabelDiff !== 0;
          } else {
            return obj.countOfLabelDiff !== 0;
          }
        }
      })
      .sort((a, b) => {
        // Apply different sorting logic based on the value of 'tag'
        if (tag !== "countOfLabel" && tag !== "countOfLabelDiff") {
          return (
            Math.abs(b[tag + "PctDiff"] * b.countOfLabelDiff) -
            Math.abs(a[tag + "PctDiff"] * a.countOfLabelDiff)
          );
        } else if (tag === "countOfLabel") {
          return Math.abs(b["countOfLabelDiff"]) - Math.abs(a["countOfLabelDiff"]);
        } else {
          // Assuming 'countOfLabelDiff' is a property that should be weighted by 'countOfLabel'
          const weightedDiffA = a["countOfLabelDiff"] * a["countOfLabel"];
          const weightedDiffB = b["countOfLabelDiff"] * b["countOfLabel"];
          return Math.abs(weightedDiffB) - Math.abs(weightedDiffA);
        }
      });
    return result;
  }

  get crumbs() {
    return [
      {
        text: this.model!.name,
        disabled: true,
      },
      {
        text: "Graph overview",
        disabled: true,
      },
    ];
  }

  public setTime(time) {
    this.time = time;
    if (this.time === "minutes") {
      this.decimalPoints = 0;
    } else {
      this.decimalPoints = 2;
    }
  }

  get multiplier() {
    if (this.time === "minutes") {
      return 60;
    } else if (this.time === "hours") {
      return 60 * 60;
    } else if (this.time === "days") {
      return 60 * 60 * 24;
    } else {
      return 1;
    }
  }

  public getFirstDayOfMonth(dateStr: string): string {
    // Create a new Date object from the date string
    const date = new Date(dateStr);

    // Set the day of the month to the 1st
    date.setDate(1);

    // Format the date back to a string in the format "yyyy-mm-dd"
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading 0 if necessary
    return `${year}-${month}`;
  }

  get maxAndMinDate() {
    let minDate: any = "";
    let maxDate: any = "";

    if (this.allTimeStamps.length > 0) {
      minDate = new Date(Math.min(...this.allTimeStamps.map((date) => new Date(date).getTime())));
      maxDate = new Date(Math.max(...this.allTimeStamps.map((date) => new Date(date).getTime())));
      // safety set to first day of month
      maxDate.setDate(1);
      minDate.setDate(1);
      maxDate.toISOString().slice(0, 10);
      minDate.toISOString().slice(0, 10);
    }
    return { minDate, maxDate };
  }

  get allTimeStamps() {
    if (!this.dataGraph.timestamps) {
      return [];
    }
    const uniqueArray: string[] = [...new Set(Object.values(this.dataGraph.timestamps))].map(
      String,
    );
    const dates = uniqueArray.map((date) => new Date(date));
    dates.sort((a, b) => a.getTime() - b.getTime());
    let timeline: any = [];

    if (this.dateBucketType === "month") {
      const currentDate = new Date(uniqueArray[0]);
      while (currentDate <= dates[dates.length - 1]) {
        const year = currentDate.getFullYear();
        // getMonth returns 0-11, so add 1 for the correct month number and pad with a leading zero if necessary
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const formattedDate = `${year}-${month}`;
        timeline.push(formattedDate);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      // mapping a slice of the first 7 tokens to each item in uniqueArray
      timeline = timeline.map((item: string) => item.slice(0, 7));
    } else if (this.dateBucketType === "week") {
      let currentDate = startOfWeek(new Date(uniqueArray[0]));

      while (currentDate <= endOfWeek(dates[dates.length - 1])) {
        timeline.push(format(currentDate, "yyyy-MM-dd"));
        currentDate = addWeeks(currentDate, 1);
      }
    }

    return timeline;
  }

  public checkAllowedDateStart(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() + 1);

    const allTimestampsRollbacked = this.allTimeStamps.map(this.getFirstDayOfMonth);

    if (
      allTimestampsRollbacked.includes(date) &&
      currentDate <= new Date(this.maxAndMinDate.maxDate)
    ) {
      if (this.endDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const endDateObj: Date = new Date(this.endDate);
        // const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (endDateObj >= currentDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public checkAllowedDateEnd(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() - 1);

    const allTimestampsRollbacked = this.allTimeStamps.map(this.getFirstDayOfMonth);

    if (
      allTimestampsRollbacked.includes(date) &&
      currentDate >= new Date(this.maxAndMinDate.minDate)
    ) {
      if (this.startDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const startDateObj: Date = new Date(this.startDate);
        // const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() - 1);
        if (currentDate >= startDateObj) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  get topColumnsByMeanDiff() {
    let result: any = {};
    result = Object.entries(this.dataGraph.column.chosenColumn)
      .map(([index, name]) => ({
        name: this.dataGraph.column.chosenColumn[index.toString()],
        score: this.dataGraph.column.meanDiff[index.toString()],
        meanResponse: this.dataGraph.column.meanResponse[index.toString()],
        meanResolve: this.dataGraph.column.meanResolve[index.toString()],
        meanSpent: this.dataGraph.column.meanSpent[index.toString()],
        totalSpent: this.dataGraph.column.totalSpent[index.toString()],
      }))
      .sort((a, b) => b.score - a.score);
    return result;
  }

  get topCategoriesByMeanDiff() {
    let result: any = {};
    result = Object.entries(this.dataGraph.category["label text"])
      .map(([index, name]) => {
        const intIndex = parseInt(index, 10);
        return {
          name: this.dataGraph.category["label text"][intIndex],
          score: this.dataGraph.category.meanDiff[intIndex],
          meanResponse: this.dataGraph.category.meanResponse[intIndex],
          meanResolve: this.dataGraph.category.meanResolve[intIndex],
          meanSpent: this.dataGraph.category.meanSpent[intIndex],
          totalSpent: this.dataGraph.category.totalSpent[intIndex],
        };
      })
      .sort((a, b) => b.score - a.score);
    return result;
  }

  get maxColumnMeanSpentValue() {
    const meanSpent: { [key: string]: number } = this.dataGraph.column.meanSpent;
    const sortedEntries = Object.entries(meanSpent).sort((a, b) => b[1] - a[1]);
    const top = sortedEntries.map((entry) => ({ name: entry[0], score: entry[1] }));
    return { top: top[0].score, bottom: top[top.length - 1].score };
  }

  get maxCategoryMeanSpentValue() {
    const meanSpent: { [key: string]: number } = this.dataGraph.category.meanSpent;
    const sortedEntries = Object.entries(meanSpent).sort((a, b) => b[1] - a[1]);
    const top = sortedEntries.map((entry) => ({ name: entry[0], score: entry[1] }));
    return { top: top[0].score, bottom: top[top.length - 1].score };
  }

  public filterOnCategory(clickedLabel) {
    console.log("clicked label", clickedLabel);
    this.$router
      .push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time/category/${clickedLabel}?column=${this.column}&datebucket=${this.dateBucketType}&filters=${this.encodeFilters}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
      )
      .catch((err) => {});
  }

  // free inferences left
  public async getSubscription() {
    await api
      .getSubscription(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.subscribedToPlan = r.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  get workspace() {
    return readWorkspace(this.$store);
  }
  // ==================

  public async mounted() {
    console.log("mounted");
    if (this.$route.query.column === undefined) {
      this.column = -1;
    } else {
      const temp: any = this.$route.query.column;
      this.column = parseInt(temp, 10);
    }
    const encodedFilters = this.$route.query.filters;

    try {
      if (encodedFilters !== undefined && typeof encodedFilters === "string") {
        const filtersString = atob(encodedFilters);
        const filters = JSON.parse(filtersString);
        console.log("decoded", filters);
        this.finalModelFilters = filters;
      }
    } catch (error) {
      console.log("Error while decoding filter:", error);
    }

    const dateBucket = this.$route.query.datebucket;

    if (dateBucket !== undefined && typeof dateBucket === "string") {
      this.dateBucketType = dateBucket;
    }

    const aggregateConversations = this.$route.query.aggregate;
    console.log("aggregateConversations", aggregateConversations);
    if (aggregateConversations !== undefined) {
      // Convert the string to a boolean
      this.aggregateConversations = aggregateConversations === "true";
    }

    const zoomInDate = this.$route.query.zoomin;

    if (zoomInDate !== undefined && typeof zoomInDate === "string") {
      this.selectedDateZoomIn = zoomInDate;
    }

    await this.getDatasetConnectedModels();

    await this.getGraphData();
    await this.chooseDataset();
  }

  public async getGraphData() {
    this.graphError = "";
    this.loading = true;
    await api
      .getCSVTimeGraph(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.modelid, 10),
        this.startDate,
        this.endDate,
        this.column,
        this.finalModelFilters,
        this.dateBucketType,
        this.aggregateConversations,
        this.selectedDateZoomIn,
      )
      .then((response) => {
        this.dataGraph = response.data;
        console.log(this.dataGraph);
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
        this.graphError = error.response;
        this.loading = false;
      });
  }

  get tableDataHeader() {
    const rows: any[] = [];
    this.columns.forEach((column, key) => {
      const text = "";
      rows.push({
        text: this.rows[0][column.value] + text,
        value: column.value,
        align: "left",
      });
    });
    return rows;
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get previewRows() {
    return this.rows.slice(1);
  }

  get token() {
    return readToken(this.$store);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );
  }

  public rgbaifier(colorName) {
    return colors[this.snakeToCamel(colorName)].base;
  }
}
