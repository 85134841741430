
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
  IWorkspaceUserInvite,
} from "@/interfaces";

import {
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
  readToken,
} from "@/store/main/getters";

import { dispatchInviteUser, dispatchGetWorkspace } from "@/store/main/actions";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";

import { api } from "@/api";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
  },
})
export default class UsersView extends Vue {
  public editRoleDialog = false;
  public editRole = "";
  public userToEdit: any = null;
  public email: string = "";
  public role: string = "viewer";
  public loading: boolean = false;
  public headers = [
    {
      text: "Full name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "Email",
      sortable: true,
      value: "email",
      align: "left",
    },
    {
      text: "Role",
      sortable: true,
      value: "role",
      align: "left",
    },
  ];
  public triggerEdit(user) {
    // starts dialog and "exports" the user that we want to edit.
    this.editRoleDialog = true;
    this.userToEdit = user;
    this.editRole = user.role;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public validateEmail(mail) {
    return true;
  }
  get emailExists(): boolean {
    if (this.email) {
      return this.workspace!.users!.filter((user) => user.email === this.email).length > 0;
    }
    return false;
  }

  get workspaceUsers() {
    return this.workspace!.users!.filter((user) => user.role !== "api-user");
  }
  get pendingWorkspaceUsers() {
    return this.workspace!.users!.filter((user) => user.pending === true);
  }

  get emailErrors(): string[] {
    if (this.emailExists) {
      return ["This name does already exist"];
    }
    if (!this.validateEmail(this.email)) {
      return ["please fill out a valid email"];
    }
    return [];
  }

  get emailIsClean(): boolean {
    if (this.emailErrors.length === 0) {
      return true;
    }
    return false;
  }

  public async inviteUser() {
    this.loading = true;
    const invite: IWorkspaceUserInvite = {
      role: this.role,
      email: this.email,
    };
    await dispatchInviteUser(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      data: invite,
    }).then(
      (r) => {
        this.email = "";
        this.loading = false;
        // this.$router.push({
        //   path: `/main/${this.$router.currentRoute.params.workspaceid}/users`,
        // });
      },
      (reason) => {
        // console.warning(reason);
      },
    );
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  public async updateUser(userId) {
    this.loading = true;
    await api
      .updateUserWorkspaceRole(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        userId,
        this.editRole,
      )
      .then((r) => {
        this.editRoleDialog = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.loading = false;
      });
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  get token() {
    return readToken(this.$store);
  }

  public async removeUser(userId) {
    this.loading = true;
    await api
      .RemoveUserFromWorkspace(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        userId,
      )
      .then((r) => {
        this.editRoleDialog = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.loading = false;
      });
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  get workspaceUserRole() {
    const workspaces = this.userProfile?.workspaces;
    const currentWorkspace = workspaces?.filter(
      (workspace) => workspace.workspace_id === this.workspace?.id,
    );
    if (!currentWorkspace) {
      return null;
    }
    return currentWorkspace[0].role;
  }

  public async created() {
    // this.getMetrics();
  }

  get workspace() {
    return readWorkspace(this.$store);
  }
}
