
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetOriginal,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readDatasets, readDataset } from "@/store/dataset/getters";

import {
  readToken,
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
} from "@/store/main/getters";
import { api } from "@/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import { ISubscription } from "@/interfaces";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ModelCard from "@/components/ModelCard.vue";
import colors from "vuetify/es5/util/colors";

import CSATBarChart from "@/components/CSATBarChart.vue";
import { nextTick } from "vue/types/umd";


@Component({
  components: {
    ItemPreview,
    CSATBarChart,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
  },
})
export default class BrowseView extends Vue {
  public loading: boolean = false;
  public connectedModels: any = [];
  public subscribedToPlan: ISubscription = {} as ISubscription;
  public ws: WaveSurfer | null = null;
  public volume: number = 0.5;
  public currentSegment: any = null;
  public isPlaying: boolean = false;
  public wsRegions: RegionsPlugin | null = null;
  public reRenderKey: number = 0;
  public currentTime: number = 0;
  public lastUpdateTime: number = 0;
  public z: number = 1;
  public similarItems: any = [];

  public dialogSimilar: boolean = false;

  get getSimilarItems() {
    return [{
      id: "7",
      items: [
        [
          {
            speaker: "agent",
            plain_text: "Vi har just nu ett kampanjerbjudande på ett surfabonnemang för endast 99kr extra per månad. De 3 första månaderna är helt gratis. Med det får du obegränsad surf, perfekt för din nya telefon.",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Det låter bra, men jag har redan ett abonnemang som jag är nöjd med.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Som ett litet plaster på såren för den försenade leveransen kan jag erbjuda dig vårt nya surfabonnemang för 99kr/månad. Du får 3 månader gratis och obegränsad surf, perfekt för din nya telefon.",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Okej, det kan vara värt att testa. Vi kör på det.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Eftersom du väntat på telefonen kan jag erbjuda dig vårt surfabonnemang för bara 99kr/mån. Du får 3 månaders gratis surf som kompensation.",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Nej tack, jag är inte intresserad av fler abonnemang just nu.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Som kompensation för dröjsmålet har vi ett erbjudande på ett surfabonnemang för endast 99kr/månad, med 3 månader gratis. Perfekt för din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Låter bra! Aktivera abonnemanget åt mig.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "För den långa leveranstiden kan jag erbjuda vårt nya surfabonnemang för 99kr/mån, med 3 månader gratis surf. Passar bra till din nya telefon.",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Tack, men jag är inte intresserad. Jag har redan surfabonnemang.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Eftersom telefonleveransen blev försenad har vi ett kampanjerbjudande för dig: 3 månader gratis surf för bara 99kr/månaden efteråt. Perfekt med den nya telefonen!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Låter kanon! Jag tar gärna det erbjudandet.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Jag förstår att du är irriterad över förseningen. Därför har jag ett exklusivt erbjudande om 3 månaders gratis surf för dig, sen bara 99kr/månad. Passar utmärkt till din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Okej, varför inte. Det kan vara skönt med extra surf nu.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Som kompensation för dröjsmålet har jag ett fantastiskt erbjudande: 3 månader gratis surf för endast 99kr/månad efteråt. Perfekt med tanke på din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Tack, men jag behöver ingen extra surf just nu.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Eftersom du fått vänta på leveransen har vi ett exklusivt erbjudande: 3 månader gratis surf för bara 99kr/månaden sen. Det passar bra ihop med din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Låter supersmidigt! Skicka över avtalet så skriver jag på.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "För besväret med den sena leveransen har vi ett kampanjpris på 99kr/mån för obegränsad surf. Dessutom bjuder jag på de 3 första månaderna. Perfekt för din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Nej tack, jag har redan tillräckligt med surf.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Ett tips nu när du snart får din nya telefon - vi har ett kampanjerbjudande på obegränsad surf för bara 99kr/månad. Jag kan ge dig 3 månader gratis för att kompensera för dröjsmålet.",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Det låter bra faktiskt. Okej, jag tar det!",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Som ursäkt för den försenade leveransen har jag ett exklusivt erbjudande till dig: 3 månader gratis surf för endast 99kr/månaden efteråt. Perfekt till din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Tack, men jag är inte intresserad av fler abonnemang just nu.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Eftersom du fick vänta extra på leveransen har vi ett specialerbjudande för dig just nu - 3 månaders gratis obegränsad surf för bara 99kr/månaden efteråt. Passar din nya telefon som hand i handske!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Det låter kanonbra! Jag tar gärna det erbjudandet.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Jag förstår att du är besviken över den sena leveransen. Därför har vi ett exklusivt erbjudande just nu - 3 gratismånader av vårt surfabonnemang för 99kr/månaden efteråt. Perfekt tillsammans med din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Nej tack, jag är nöjd med mitt nuvarande abonnemang.",
            labels: [11],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "För att kompensera för dröjsmålet har du chansen att få 3 månader gratis surf för bara 99kr/månaden efteråt. Ett kanonerbjudande till din nya telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Visst, varför inte. Aktivera 3 månader gratis åt mig.",
            labels: [7],
          },
        ],
        [
          {
            speaker: "agent",
            plain_text: "Eftersom du fått vänta kan jag erbjuda vårt nya surfabonnemang med 3 månader gratis för bara 99kr/månaden sen. Perfekt med tanke på din nya kraftfulla telefon!",
            labels: [10],
          },
          {
            speaker: "customer",
            plain_text: "Nej tack, jag har redan tillräckligt med surf i mitt nuvarande abonnemang.",
            labels: [11],
          },
        ],
      ],
    },
    ];
  }

  public conversation = [
    {
      plain_text: " Hej det här är Ted på telekom support, hur kan jag hjälpa dig idag?",
      id: "1",
      speaker: "agent",
      start: 0,
      end: 4.99,
      labels: [1],
    },
    {
      plain_text: "Hej, jag beställde en iPhone 13 Pro för över en vecka sedan men den har fortfarande inte kommit. Vad är problemet?",
      id: "2",
      speaker: "customer",
      start: 5,
      end: 13.99,
      labels: [3],
    },
    {
      plain_text: "Hej där! Jag förstår att det måste vara frustrerande att din nya telefon inte har kommit än. Låt mig titta in det här. Vilket ordernummer har du för beställningen?",
      id: "3",
      speaker: "agent",
      start: 14,
      end: 23.99,
      labels: [],
    },
    {
      plain_text: "Ordernumret är 47322.",
      id: "4",
      speaker: "customer",
      start: 24,
      end: 28.99,
      labels: [4],
    },
    {
      plain_text: "Tack, låt mig se... Ah jag ser här att din iPhone 13 Pro tyvärr blev försenad på grund av en tillfällig brist hos vår leverantör. Den förväntas dock komma in på lager i morgon och skickas då express till dig. Du borde ha den senast på fredag. Jag ber verkligen om ursäkt för förseningen!",
      id: "5",
      speaker: "agent",
      start: 29,
      end: 46.99,
      labels: [8],
    },
    {
      plain_text: "Okej, jag antar att det får duga då. Hoppas den kommer på fredag.",
      id: "6",
      speaker: "customer",
      start: 47,
      end: 51.99,
      labels: [],
    },
    {
      plain_text: "Absolut, du kommer få den slutet av veckan. Vi har ett extra erbjudande just nu på ett riktigt bra abonnemang, det kostar bara 99kr extra i månaden för dig. Jag kan bjuda på de 3 första månaderna helt gratis. Då får du obegränsad surf vilket är perfekt nu när du snart får din nya telefon.",
      id: "7",
      speaker: "agent",
      start: 52,
      end: 67,
      labels: [5],
    },
    {
      plain_text: "Hm, det låter ju inte så dumt. Men jag har redan ett abonnemang?",
      id: "8",
      speaker: "customer",
      start: 67.01,
      end: 72.99,
      labels: [6],
    },
    {
      plain_text: "Precis, det här blir ett tillägg som ger dig massor av extra surf utöver ditt befintliga abonnemang. Perfekt nu när du snart får din nya kraftfulla telefon som du säkert kommer använda mer. Och med sommaren nästan här så är det bra att ha obegränsad surf om du ska resa och vara ute mer. Så vad säger du, vill du att jag aktiverar 3 gratismånader av det här tillägget åt dig?",
      id: "9",
      speaker: "agent",
      start: 73,
      end: 92.99,
      labels: [5, 9],
    },
    {
      plain_text: "Ja gärna, det kan vara bra att ha lite extra surf.",
      id: "10",
      speaker: "customer",
      start: 93,
      end: 96.99,
      labels: [6, 7],
    },
    {
      plain_text: "Toppen, då ordnar jag det med en gång! Du kommer se tillägget på nästa faktura. Återigen sorry för leveransförseningen, men jag hoppas den extra datan gör upp lite för det. Hör gärna av dig om du har några andra frågor!",
      id: "11",
      speaker: "agent",
      start: 97,
      end: 108.99,
      labels: [],
    },
    {
      plain_text: "Okej tack, vi hörs!",
      id: "12",
      speaker: "customer",
      start: 109,
      end: 110.99,
      labels: [],
    },
    {
      plain_text: "Ha en fortsatt bra dag! Hej då!",
      id: "13",
      speaker: "agent",
      start: 111,
      end: 115,
      labels: [],
    },
  ];

  public showSimilar(id: string) {
    const allSimilar = this.getSimilarItems;

    this.similarItems = allSimilar
      .filter((obj) => obj.id === id)
      .map((obj) => obj.items);

    if (this.similarItems.length > 0) {
      this.similarItems = this.similarItems[0];
    }

    this.dialogSimilar = true;
  }

  get ctr() {
    return this.z++;
  }

  public highestCardHeight() {
    let highestHeight = 20;
    const cards = this.$refs.cards as Vue[];
    console.log("this is cards", cards);
    if (cards !== undefined) {
      for (const card of cards) {
        const el = card.$el as HTMLElement;
        if (el.offsetHeight > highestHeight) {
          highestHeight = el.offsetHeight;
        }
      }
    }
    return highestHeight;
  }

  public formatTime(timeInSeconds: number) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  // free inferences left
  public async getSubscription() {
    await api
      .getSubscription(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.subscribedToPlan = r.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  // functions for table
  public parsedDate(timestamp) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs
      .utc(timestamp)
      .tz(timeZone)
      .format("MMMM D, YYYY h:mm A");
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  // ==================
  public getSpeakerColor(speaker: string) {
    if (speaker === 'agent') {
      return 'green';
    } else {
      return 'orange';
    }
  }

  public getSpeakerIcon(speaker: string) {
    if (speaker === 'agent') {
      return 'support_agent';
    } else {
      return 'face';
    }
  }

  public async mounted() {
    this.ws = WaveSurfer.create({
      container: this.$refs.waveform as HTMLElement,
      waveColor: '#efedf5',
      progressColor: 'orange',
      barWidth: 5,
    });

    this.wsRegions = this.ws.registerPlugin(RegionsPlugin.create());

    this.ws.load('/demo.m4a');

    this.ws.on('audioprocess', () => {
      try {
        if (this.ws) {
          const currentTime = this.ws.getCurrentTime();
          this.currentSegment = this.conversation.find((segment) =>
            segment.start <= currentTime && segment.end >= currentTime,
          );
        }
      } catch (error) {
        console.error('Error in audioprocess event listener:', error);
      }
    });

    this.ws.on('play', () => {
      this.isPlaying = true;
    });

    this.ws.on('pause', () => {
      this.isPlaying = false;
    });

    this.ws.on('finish', () => {
      this.isPlaying = false;
    });

    this.ws.on('ready', () => {
      this.createRegions();
    });

    this.ws.on('audioprocess', (time) => {
      const roundedTime = Math.round(time);
      if (roundedTime !== this.lastUpdateTime) {
        this.currentTime = roundedTime;
        this.lastUpdateTime = roundedTime;
      }
    });

  }

  get allLabels() {
    return [
      {
        id: 1,
        color: "blue",
        name: "Greeting:accurate",
      },
      {
        id: 2,
        color: "red",
        name: "Problem",
      },
      {
        id: 3,
        color: "orange",
        name: "Problem:mobiletelephony:delivery",
      },
      {
        id: 4,
        color: "purple",
        name: "Order_number:47322",
      },
      {
        id: 10,
        color: "indigo",
        name: "Sales attempt",
      },
      {
        id: 5,
        color: "indigo",
        name: "Sales attempt:unlimited surf",
      },
      {
        id: 6,
        color: "grey",
        name: "Sales reaction",
      },
      {
        id: 7,
        color: "green",
        name: "Sale accepted",
      },
      {
        id: 11,
        color: "red",
        name: "Sale declined",
      },
      {
        id: 8,
        color: "pink",
        name: "Reason",
      },
      {
        id: 9,
        color: "blue-grey",
        name: "New phone and summer",
      },
    ];
  }

  public labelById(id: number) {
    return this.allLabels.find((label) => label.id === id);
  }

  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    try {
      if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      } else {
        return `rgb(${r}, ${g}, ${b})`;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }

  public async createRegions() {
    if (this.ws && this.wsRegions) {
      this.conversation.forEach((conversation) => {
        const labels = conversation.labels.map(
          (labelId) => this.allLabels.find((l) => l.id === labelId)).filter(Boolean);
        if (labels !== undefined && labels.length > 0 && this.wsRegions !== null) {
          const color = labels.length > 1 && labels !== undefined ? 'rgba(204,204,204,0.2)' :
          (labels[0] && labels[0].color ? this.hexToRGBA(colors[this.snakeToCamel(labels[0].color)].base, 0.2) :
           'rgba(204,204,204,0.2)');
          this.wsRegions.addRegion({
            start: conversation.start,
            end: conversation.end,
            color,
            drag: false,
            resize: false,
          });

          // Add a black marker at the end of the region
          this.wsRegions.addRegion({
            start: conversation.start,
            color: 'black',
          });
        }
      });
    }
  }
  public async beforeRouteUpdate(to, from, next) {
    console.log("DESTROYING");
    if (this.ws) {
      this.ws.destroy();
    }
    next();
  }

  public beforeDestroy() {
    // Your cleanup code here
    if (this.ws) {
      this.ws.destroy();
    }
  }

  @Watch('isPlaying')
  public onIsPlayingChanged(newIsPlaying: boolean, oldIsPlaying: boolean) {
    console.log(`Playback status changed from ${oldIsPlaying} to ${newIsPlaying}`);
  }

  public skipForward() {
    if (this.ws) {
      const currentTime = this.ws.getCurrentTime();
      const nextSegment = this.conversation.find((segment) => segment.start > currentTime);
      if (nextSegment) {
        this.ws.seekTo(nextSegment.start / this.ws.getDuration());
        this.currentSegment = nextSegment;
        const startPercent = nextSegment.start / this.ws.getDuration();

        // Seek to the start of the section and start playing
        this.ws.seekTo(startPercent + 0.01);
      }
    }
  }

  public skipBackward() {
    if (this.ws) {
      const currentTime = this.ws.getCurrentTime();
      const previousSegments = this.conversation.filter(
        (segment) => segment.end < currentTime);
      const previousSegment = previousSegments[previousSegments.length - 1];
      if (this.currentSegment && currentTime - this.currentSegment.start > 2) {
        // If more than 2 seconds into the current segment, seek to the start
        this.ws.seekTo(this.currentSegment.start / this.ws.getDuration());
      } else if (previousSegment) {
        // If less than 2 seconds into the current segment, seek to the previous segment
        this.ws.seekTo(previousSegment.start / this.ws.getDuration());
        this.currentSegment = previousSegment;
      }
    }
  }

  public play() {
    if (this.ws) {
      this.ws.play();
    }
  }

  public pause() {
    if (this.ws) {
      this.ws.pause();
    }
  }

  @Watch('volume')
  public onVolumeChanged(newVolume: number) {
    if (this.ws) {
      this.ws.setVolume(newVolume);
    }
  }

  public resetAudio() {
    if (this.ws) {
      this.ws.stop();
      this.ws.seekTo(0);
      this.currentSegment = this.conversation[0];
    }
  }

  public playSection(start: number, end: number) {
    if (this.ws) {
      // Convert the start and end times to percentages of the total duration
      const startPercent = start / this.ws.getDuration();
      const endPercent = end / this.ws.getDuration();

      // Seek to the start of the section and start playing
      this.ws.seekTo(startPercent);
      this.ws.play();

      // Set up an event listener to stop playback at the end of the section
      const onAudioProcess = () => {
        if (this.ws && this.ws.getCurrentTime() >= end) {
          this.ws.pause();
          this.ws.seekTo((startPercent + endPercent) / 2);
          // Remove the event listener to avoid stopping playback at this time in the future
          this.ws.un('audioprocess', onAudioProcess);

        }
      };
      this.ws.on('audioprocess', onAudioProcess);
    }
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get token() {
    return readToken(this.$store);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }
}
