
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IInferencePrompt } from "@/interfaces";
import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import { dispatchCreateInferencePrompt } from "@/store/dataset/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
  },
})
export default class CreateInferencePrompt extends Vue {
  public id: number = 1;
  public dialogLabel: boolean = true;
  public loading: boolean = false;
  public e6: number = 0;
  public uploadError: any = null;
  public redirected: boolean = false;

  public promptName: string = "";
  public prompt: string = "";

  public ModelSelectError: string = "";
  public loadingModel: boolean = false;
  public generativeModels: any[] = [{ name: "Default model", description: "Default generative model" , "status": "trained"}];
  public selectedModel: any = null;

  public async mounted() {
    // redirected = we were redirected from model settings
    if (this.$route.query.redirected === "true") {
      this.redirected = true;
    }
    await this.getGenerativeModels();
  }


  get isAdmin() {
    return readHasAdminAccess;
  }




  public async getGenerativeModels() {
    this.loadingModel = true;
    await api
      .getGenerativeModels(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        console.log("RESPONSE", r.data);

        // extend the list with r.data
        this.generativeModels = this.generativeModels.concat(r.data);

        this.loadingModel = false;
        // this.generativeModels = Array(20).fill(this.generativeModels).flat();

        //
      })
      .catch((error) => {
        console.log("error when getting generative models", error);
      });
  }

  public sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  public changeColor(color) {}

  get promptNameErrors(): string[] {
    if (!this.promptNameIsClean && !this.loading) {
      return ["Prompt is too long or too short"];
    }
    return [];
  }

  get promptErrors(): string[] {
    if (!this.promptIsClean && !this.loading) {
      return ["Prompt is too long or too short"];
    }
    return [];
  }

  get promptNameIsClean(): boolean {
    const isClean = this.promptName.length >= 1 && this.promptName.length <= 20;
    return isClean;
  }

  get modelSelected(): boolean {
    return this.selectedModel !== null;
  }

  get promptIsClean(): boolean {
    const isClean = this.prompt.length >= 1 && this.prompt.length <= 5000;

    return isClean;
  }


  get token() {
    return readToken(this.$store);
  }

  
  public async createInferencePrompt() {

    const newInferencePrompt: any = {
      name: this.promptName,
      prompt: this.prompt,
      model_id: this.generativeModels[this.selectedModel].id ?? null,
      dataset_id: parseInt(this.$router.currentRoute.params.id, 10),
    };
    this.loading = true;

    //   workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    //   datasetId: parseInt(this.$router.currentRoute.params.id, 10),
    //   inferencePrompt: newInferencePrompt,
    // });
    // await api
    //   .createInferencePrompt(this.token)
    await dispatchCreateInferencePrompt(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: parseInt(this.$router.currentRoute.params.id, 10),
      inferencePrompt: newInferencePrompt,
    })
      .then((r) => {
        this.prompt = "";
        this.promptName = "";
        this.loading = false;
        this.pushRouter();
      })
      .catch((error) => {
        this.loading = false;
        console.log("error when updating inference prompt", error);
        this.uploadError = error.response;
      });
  }

  public pushRouter() {
    if (this.redirected === false) {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/settings`,
      );
    } else {
      this.$router.push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/settings`,
      });
    }
  }
}
