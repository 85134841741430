
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
  IDataset,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readConnectedDatasets,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetPredictions,
  dispatchShareModel,
  dispatchGetPredictedItems,
  dispatchRetrainModel,
  dispatchSetPreviewHeader,
  dispatchGetModels,
  dispatchGetConnectedDatasets,
} from "@/store/model/actions";

import { readToken } from "@/store/main/getters";
import { api } from "@/api";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import InternalDatasetCard from "@/components/InternalDatasetCard.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import LabelPieChart from "@/components/LabelPieChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import DatasetCard from "@/components/DatasetCard.vue";
import ItemPreviewConfusionMatrix from "@/components/ItemPreviewConfusionMatrix.vue";
import dayjs from "dayjs";
import { filter } from "vue/types/umd";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    LabelChart,
    LabelPieChart,
    ToolbarButton,
    RoundProgress,
    DatasetCard,
    InternalDatasetCard,
    ItemPreviewConfusionMatrix,
  },
})
export default class DashboardView extends Vue {
  public dialogMissingExamples: boolean = false;
  public metricThreshold: number = 0.0;
  public text: string = "";
  public modelPredictions: any[] = [];
  public inferenceError: any = null;
  public retrainError: any = null;
  public loading: boolean = false;
  public availableDatasets: any[] = [];
  public dialogConnect: boolean = false;
  public e6: number = 0;

  public BYOL = false;
  public loadingScreen: boolean = false;
  public keyUpdate: number = Math.floor(Math.random() * (100 + 1));
  public tabModel: number = 0;

  public chosenDatasetId: number;
  public uploadError: any = null;

  public dialogDisconnect: boolean = false;
  public predictedDialog: boolean = false;
  public predictedLabel: string | null = null;
  public dialogDeploy: boolean = false;
  public isDeploy: boolean = false;

  public mediaRecorder: MediaRecorder;
  public chunkus: Blob[] = [];
  public recording: boolean = false;
  public loadingTranscript: boolean = false;
  public countDown: number = 10;
  public timeOutId: any;
  public deployError: any = null;

  public expandedRow: number | null = null;
  public userExampleCount: any = {};

  public intervalId: number | null = null;

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public async created() {
    this.getUserExampleCount();
  }

  public async getUserExampleCount() {
    this.loading = true;
    await api
      .getUserExampleCount(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.loading = false;
        this.userExampleCount = r.data;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
      });
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  public navigateToModel(id: number) {
    const workspaceId = this.$router.currentRoute.params.workspaceid;
    const path = `/main/${workspaceId}/classification/${id}/dashboard/dashboard`;
    this.$router.push(path);
  }

  get dependentOnModels() {
    const modelsToDeploy: any[] = [];

    // Check if there are any label filters
    const allFilters = this.model?.filters;
    
    for (let i = 0; i < allFilters.filters.length; i++) {
      const filterGroup = allFilters.filters[i];
      
      for (let j = 0; j < filterGroup.filters.length; j++) {
        const filter = filterGroup.filters[j];
        if (filter.filter_type === "label") {
          // Loop through this.models and check if model.status === 'deployed' for the inherited models
          for (let k = 0; k < this.models.length; k++) {
            const model = this.models[k];
            if (model.id === filter.inherited_from && model.status !== "deployed") {
              // If not, add to a list of models that need to be deployed
              modelsToDeploy.push(model);
            }
          }
        }
      }
    }

    return modelsToDeploy;
  }
  public getFilterColumnName(columnId: number, dataset: IDataset) {
    const metaData = dataset.meta_data.columns[columnId];
    if (metaData) {
      return metaData.name;
    } else {
      return "Unknown";
    }
  }

  public expandRow(index) {
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.models.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public getModelById(id: number) {
    const modelo = this.models.find((m) => m.id === id);
    if (modelo) {
      return modelo;
    } else {
      return { name: "Unknown" };
    }
  }

  get models() {
    return readModels(this.$store);
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }

  public createCombined(datasetId) {
    const datasetFilters = this.model?.filters.filters.filter((f) => f.dataset_id === datasetId);

    if (datasetFilters.length > 0) {
      return datasetFilters[0].filters.map((item) => ({
        ...item,
        combined: item.column_id ? item.column_id : item.inherited_from,
      }));
    } else {
      return [];
    }
  }

  public startRecord() {
    this.countDown = 10;
    this.inferenceError = null;
    this.countDownTimer();

    this.recording = true;
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start(1000);
      this.mediaRecorder.ondataavailable = (e) => {
        this.chunkus.push(e.data);
      };
      this.mediaRecorder.onstop = () => {
        const tracks = stream.getTracks();
        // When all tracks have been stopped the stream will
        // no longer be active and release any permissioned input
        tracks.forEach((track) => track.stop());
      };
    });
  }

  get safeRatio() {
    if (this.model !== undefined) {
      let percentage = Math.round((this.model.total_labeled / this.model.item_count) * 100);
      if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    } else {
      return 0;
    }
  }

  public async stopRecord() {
    this.recording = false;
    clearTimeout(this.timeOutId);
    this.loadingTranscript = true;
    if (this.mediaRecorder.state && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop();
    }
    let audioBlob: Blob;
    this.mediaRecorder.addEventListener("stop", () => {
      audioBlob = new Blob(this.chunkus, { type: "audio/wav" });
      this.chunkus = [];
      this.queryWhisper(audioBlob);
    });
  }

  public countDownTimer() {
    if (this.countDown > 0) {
      this.timeOutId = setTimeout(() => {
        this.countDown -= 1;
        this.countDownTimer();
      }, 1000);
    } else {
      this.stopRecord();
    }
  }

  public async delay(ms: number) {
    await new Promise<void>((f) => setTimeout(f, ms));
  }

  public async queryWhisper(blob: Blob) {
    await api
      .callWhisper(this.token, blob)
      .then((response) => {
        this.text = response.data.text;
        this.loadingTranscript = false;
        this.testModelInference();
      })
      .catch((error) => {
        console.log(error.response.status);
        const obj = {
          data: {
            detail: "Could not transcribe the audio, please try again!",
          },
        };
        this.loadingTranscript = false;
        this.inferenceError = obj;
      });
  }

  public async shareModel() {
    this.inferenceError = null;
    this.loading = true;
    await dispatchShareModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    })
      .then((res) => {
        this.loading = false;
        this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/settings/shared`);
      })
      .catch((err) => {
        this.loading = false;
        this.inferenceError = err.response;
      });
  }

  get statusCheck() {
    // returns true if status indicates training/uploading etc
    if (this.model?.status === "ready" || this.model?.status === "deployed") {
      if (this.model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }

  public dateParse(ts) {
    return dayjs(ts).format("MMMM D, YYYY h:mm A");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public disconnectChoice(datasetId) {
    this.dialogDisconnect = true;
    this.chosenDatasetId = datasetId;
  }

  public toggleDeployDialog(isDeploy: boolean) {
    this.isDeploy = isDeploy;
    if (this.dialogDeploy) {
      this.dialogDeploy = false;
      return;
    }
    this.dialogDeploy = true;
    return;
  }

  public async deployModel(deploy: boolean) {
    this.loading = true;
    this.deployError = null;
    await api
      .deployModel(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        deploy,
      )
      .then((r) => {
        this.loading = false;
        this.dialogDeploy = false;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.deployError = uploadError.response;
      });
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  public async disconnectDataset() {
    this.loading = true;
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await api
      .disconnectDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.chosenDatasetId,
      )
      .then((r) => {
        this.e6 = 0;
        this.loading = false;
        this.uploadError = null;
        this.dialogDisconnect = false;
        this.chosenDatasetId = -1;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.uploadError = uploadError.response;
        this.dialogDisconnect = false;
        this.chosenDatasetId = 1;
      });
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await this.getAvailableDatasets();
    await this.getConnectedDatasets();
  }

  public async getAvailableDatasets() {
    await api
      .getAvailableDatasets(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.availableDatasets = r.data;
      })
      .catch((error) => {
        console.log("UploadError", error.response);
      });
  }

  get availablePrivateDatasets() {
    const unsortedAvailableDatasets = this.availableDatasets.filter(
      (dataset) => dataset.public !== true,
    );
    return unsortedAvailableDatasets.slice().sort((a, b) => b.id - a.id);
  }

  public async getPredictions() {
    await dispatchGetPredictions(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
    });
  }

  public nextStep() {
    this.e6++;
  }

  public async getConnectedDatasets() {
    await dispatchGetConnectedDatasets(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    });
    this.keyUpdate = this.keyUpdate + 1;
  }

  public labelColorByName(labelName) {
    return this.labels!.filter((labelItem) => labelItem.name === labelName)[0].color;
  }

  public async testModelInference() {
    this.inferenceError = null;
    this.loading = true;

    await api
      .testModelInference(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.text,
      )
      .then((r) => {
        this.modelPredictions = [];
        r.data!.forEach((l) => {
          const filteredLabel = this.labels!.filter((labelItem) => labelItem.id === l.label);
          this.modelPredictions.push({
            score: l.score * 100,
            name: filteredLabel[0].name,
            color: filteredLabel[0].color,
          });
        });
        this.loading = false;
      })
      .catch((inferenceError) => {
        this.modelPredictions = [];
        this.loading = false;
        console.log("UploadError", inferenceError.response);
        this.inferenceError = inferenceError.response;
      });
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  public async mounted() {
    await this.fetchModels();
    this.intervalId = window.setInterval(() => {
      this.fetchModels();
    }, 30000);
    this.getPredictions();
    this.getConnectedDatasets();
    await this.getAvailableDatasets();
    if (this.zeroLabels.length > 0 && !this.statusCheck) {
      this.dialogMissingExamples = true;
    }

    // metric threshold ska vara 0.5 om multilabel
    this.model!.label_containers[0].type === "multi"
      ? (this.metricThreshold = 0.5)
      : (this.metricThreshold = 0.0);
  }

  get token() {
    return readToken(this.$store);
  }

  get connectedDatasets() {
    const connectedDatasetsUnsorted = readConnectedDatasets(this.$store);
    return connectedDatasetsUnsorted.slice().sort((a, b) => b.id - a.id);
  }

  get previewItems() {
    return readPreviewItems(this.$store);
  }

  get userPreviewItems() {
    const userPreviewItems = this.previewItems!.filter((item) => {
      if (item.user_labels[0] && "label_container" in item.user_labels[0]) {
        return item.user_labels[0].label_container === this.model!.label_containers[0].id;
      }
      // Exclude items without label_container
      return false;
    });
    return userPreviewItems;
  }

  get predictedPreviewItems() {
    const userPreviewItems = this.previewItems!.filter((item) => {
      if (item.user_labels[0] && "label" in item.user_labels[0]) {
        return item.user_labels[0].label === -1;
      }
      return false;
    });
    return userPreviewItems;
  }
  get predictions() {
    return readPrediction(this.$store);
  }

  get emptyPredictionsAndLabels() {
    const predcounts = readPredictionsByIds(this.$store);

    let hasEmpty = true;
    this.labels.forEach((l) => {
      if (l.counter > 0) {
        hasEmpty = false;
      }
    });

    for (const key in predcounts) {
      if (predcounts[key] > 0) {
        hasEmpty = false;
      }
    }
    return hasEmpty;
  }

  get predictionsByIds() {
    const predcounts = readPredictionsByIds(this.$store);
    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    if (this.labels) {
      this.labels!.forEach((l) => {
        res = { ...res, ...{ [l.id]: 0 } };
      });
    }

    return res;
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.id);
  }

  get zeroLabels() {
    return this.labels.filter((label) => {
      // Check if label.counter is 0 or if userExampleCount for this label id is 0
      return (
        label.counter === 0 &&
        this.userExampleCount[label.id] !== undefined &&
        this.userExampleCount[label.id] === 0
      );
    });
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.$router.currentRoute.params.id);
  }

  public async getPredictedItems(predictedLabelId) {
    document.body.style.cursor = "wait";
    await dispatchGetPredictedItems(this.$store, {
      modelId: this.model!.id,
      predictedLabelId,
      threshold: this.metricThreshold,
    })
      .then(() => {
        document.body.style.cursor = "default";
      })
      .catch((err) => {
        document.body.style.cursor = "default";
      });
    this.predictedLabel = this.labels.filter((label) => label.id === predictedLabelId)[0].name;
    this.predictedDialog = true;
  }

  public async retrain() {
    this.loading = true;
    this.retrainError = "";

    dispatchRetrainModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: this.model!.id,
    })
      .then((res) => {
        this.loading = false;
      })
      .catch((error) => {
        this.retrainError = error.response;
        console.log("ERROR", this.retrainError);
        this.loading = false;
      });
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public beforeRouteLeave(to: any, from: any, next: any) {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
    }
    next();
  }

  public async fetchModels() {
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    this.getPredictions();
    this.getConnectedDatasets();
  }

  @Watch("$route", { immediate: true, deep: true })
  public async onRouteChange(to, from) {
    if (to && from && to.params.id !== from.params.id) {
      await dispatchGetModels(this.$store, {
        id: +to.params.workspaceid,
      });
      // Fetch the specific model
      await readModel(this.$store)(+to.params.id);
    }
    this.getPredictions();
    this.getConnectedDatasets();
    this.getAvailableDatasets();
    if (this.zeroLabels.length > 0 && !this.statusCheck) {
      this.dialogMissingExamples = true;
    }

    // metric threshold ska vara 0.5 om multilabel
    this.model!.label_containers[0].type === "multi"
      ? (this.metricThreshold = 0.5)
      : (this.metricThreshold = 0.0);
  }
}
