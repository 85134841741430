
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class MiniBarChart extends Vue {
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public totalValue!: any;
  @Prop({ default: 0 })
  public partValue!: any;
  @Prop({ default: "part" })
  public partValueName!: any;
  @Prop({ default: "black" })
  public color!: any;
  @Prop({ default: 0 })
  public max!: any;
  @Prop({ default: "Total" })
  public totalValueName!: any;

  get colorCopy() {
    return this.color;
  }


  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelAutomaticColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }
  get trimmedName() {
    if (this.partValueName.length > 10) {
      return this.partValueName.substring(0, 10) + "...";
    } else {
      return this.partValueName;
    }
  }

  get labelAutomaticBarData() {
    return {
      cliponaxis: false,
      x: [this.totalValueName, this.trimmedName],
      y: [this.totalValue, this.partValue],
      type: "bar",
      hoverinfo: 'text+label',
      textposition: "outside",
      text: [this.totalValue, this.partValue],
      marker: {
        width: 0.5,
        color: ["black", this.hexToRGBA(colors[this.snakeToCamel(this.colorCopy)].base)],
      },
    };
  }

  get labelBarLayout() {
    return {
      margin: { t: 15, r: 20, l: 20, b: 0 },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "stack",
      xaxis: {
        type: 'category',
        automargin: true,
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        range: [0, this.max],
        showticklabels: false,
        automargin: true,
        showgrid: false,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: false,
      },
    };
  }
  @Emit()
  public focuslabel(clickedLabel) {
  }
}
