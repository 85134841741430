
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
  IStars,
  IPreviewParametersSubCategories,
  IColumnInformation,
  IModelFilterString,
  IModelFilterTimestamp,
  IModelFilterLabel,
  IPreviewParametersSubCategoriesDate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetOriginal,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readDatasets, readDataset } from "@/store/dataset/getters";

import {
  readToken,
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
} from "@/store/main/getters";
import { api } from "@/api";
import dayjs from "dayjs";

import { ISubscription } from "@/interfaces";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ModelCard from "@/components/ModelCard.vue";
import TimeSeries from "@/components/TimeSeries.vue";
import ClusterPieChart from "@/components/ClusterPieChart.vue";
import CSATBarChart from "@/components/CSATBarChart.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import TimeTimeSeries from "@/components/TimeTimeSeries.vue";
import MiniBarChart from "@/components/MiniBarChart.vue";
import TimeGraphSettings from "@/components/TimeGraphSettings.vue";
import TimeGraphTextCard from "@/components/TimeGraphTextCard.vue";
import SubCategoryPicker from "@/components/SubCategoryPicker.vue";
import TimeBarChartCategory from "@/components/TimeBarChartCategory.vue";


import { startOfWeek, endOfWeek, addWeeks, format, getWeek, getYear } from "date-fns";

@Component({
  components: {
    MiniBarChart,
    TimeTimeSeries,
    LottieAnimation,
    ItemPreview,
    CSATBarChart,
    TimeSeries,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
    ClusterPieChart,
    TimeGraphSettings,
    TimeGraphTextCard,
    SubCategoryPicker,
    TimeBarChartCategory,
  },
})
export default class GraphCategoryView extends Vue {
  public menu: boolean = false;
  public showplease: boolean = true;
  public loading: boolean = false;
  public loadingEffectByCategory: boolean = false;
  public loadingClusterPreview: boolean = false;
  public dataGraph: any = false;
  public graphError: string = "";
  public dashboardItemsError: string = "";
  public subscribedToPlan: ISubscription = {} as ISubscription;
  public category: number = -1;
  public effectCategoryColumns: any = {};
  public page: number = 1;
  public subCategoryPreviewItems: any = {};
  public keyToShow: string = "countOfLabel";
  public running: boolean = false;
  public time: string = "minutes";
  public decimalPoints: number = 0;
  public SubkeyToShow: string = "countOfLabel";

  public showEditField: boolean = false;
  public editValue: string = "";
  public oldClusterName: string = "";
  public clusterError: any = null;

  public endDate: any = "";
  public startDate: any = "";
  public previousEndDate: any = "";
  public previousStartDate: any = "";
  public menuEnd: boolean = false;
  public menuStart: boolean = false;
  public column: any = -1;

  public selectedDateZoomIn: string = "all";

  public dialogFilter: boolean = false;
  public d6: number = 0;
  public recommendationFilter: number = -1;
  public filterError: any = null;
  public filterType: string = "must";
  public columnInformation: IColumnInformation = {} as IColumnInformation;
  public pickedValues: any[] = [];
  public showAll: boolean = false;
  public totalItems: number = 0;
  public modelFilters: any = [];
  public expandedRow: number | null = null;
  public loadingButton: boolean = false;
  public connectedModels: any[] = [];
  public tempModelFilters: any = [];
  public expandedRowModel: number | null = null;
  public columns: any[] = [];
  public rows: any[] = [];
  public rowsToShow: any[] = [];

  public filterDataset: boolean = true;
  public pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };

  public paginationHistoricalVolumeSubCategories: any = { rowsPerPage: 4 };
  public paginationReversedTimeStamps: any = { rowsPerPage: 6 };
  public selectedDate: string = "";

  public aggregateConversations: boolean = false;

  public historicalKeyColumn: string = "countOfLabel";

  public paginationDatesPreview: any = { rowsPerPage: 6 };
  public selectedDatePreview: string = "";

  public dateBucketType: string = "month";

  public toggleAllDatesForPreview: boolean = false;

  public pendingUpdates: any = [];
  public graphSettingsKey: number = 0;
  public subCategoryKey: number = 3;
  public finalModelFilters: any = [];
  public pickSubCategoryDialog: boolean = false;

  public selectedOption: string | null = null;

  public selectedSortOption: any =  {text: "Volume", value: "countOfLabel"};

  public tabModel: number = 1;

  public sortOptions = [
    {text: "Volume", value: "countOfLabel"},
    {text: "Mean agent workload",  value: "meanSpent"},
    {text: "Mean response time", value: "meanResponse"},
    {text: "Mean customer workload", value: "meanResolve"},
  ];

  get dynamicSubKeyTranslator() {
    if (this.selectedDateZoomIn === "all") {
      return {
        Volume: "countOfLabel",
        "Category Δ": "meanChangeVolume",
        "Category Δ weighted": "meanChangeVolumeWeighted",
        "Mean agent workload": "meanSpent",
        "Mean response time": "meanResponse",
        "Mean customer workload": "meanResolve",
        "Total response time": "totalResponse",
        "Total customer workload": "totalResolve",
        "Total agent workload": "totalSpent",
      };
    } else {
      return {
        Volume: "countOfLabel",
        "Category Δ": "meanChangeVolume",
        "Category Δ (WoW)": "meanChangeVolumePeriod",
        "Category Δ weighted": "meanChangeVolumeWeighted",
        "Category Δ weighted (WoW)": "meanChangeVolumeWeightedPeriod",
        "Mean agent workload": "meanSpent",
        "Mean response time": "meanResponse",
        "Mean customer workload": "meanResolve",
        "Total response time": "totalResponse",
        "Total customer workload": "totalResolve",
        "Total agent workload": "totalSpent",
      };
    }
  }

  public keyTranslator = {
    Volume: "countOfLabel",
    "Mean agent workload": "meanSpent",
    "Mean response time": "meanResponse",
    "Mean customer workload": "meanResolve",
  };

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public historicalTranslatorColumn = {
    "Volume": "countOfLabel",
    "Weighted volume": "countOfLabelDiff",
    "Mean agent workload": "meanSpent",
    "Mean response time": "meanResponse",
    "Mean customer workload": "meanResolve",
  };

  public changeColumn(column) {
    console.log("COLUMN")
    this.pickSubCategoryDialog = false;

    const action = {
      changeSubCategory: {column: column}
    };

    this.createPendingUpdates(action);
  }

  public async selectDateZoomIn(selectDate: string) {
    this.selectedDateZoomIn = selectDate;
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time/category/${this.category}?column=${this.column}&filters=${this.encodeFilters}&datebucket=${this.dateBucketType}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
    );
  }

  public switchAggregate(aggregate: boolean) {
    const action = {
        changeAggregate: {selectedDateZoomIn: "all", aggregateConversations: aggregate}
      };

      this.createPendingUpdates(action);
  }

  public updateKeys() {
    this.graphSettingsKey = this.graphSettingsKey + 1;
    this.subCategoryKey = this.subCategoryKey + 3;
    this.pendingUpdates = [];
    this.modelFilters = this.finalModelFilters.slice();
  }

  public async applyPendingUpdates() {
    this.pendingUpdates.forEach((action) => {
      if ('changeSubCategory' in action) {
        this.column = action.changeSubCategory.column;
    }
    if ('changeAggregate' in action) {
      this.selectedDateZoomIn = "all";
      this.aggregateConversations = action.changeAggregate.aggregateConversations;
    }
    if ('changeDateBucket' in action) {
      this.selectedDateZoomIn = "all";
      this.dateBucketType = action.changeDateBucket.dateBucketType;
    }
    });
    this.pendingUpdates = [];
    
    if (!this.arraysContainSameObjects(this.finalModelFilters, this.modelFilters)) {
      this.finalModelFilters = this.modelFilters.slice();
    }

    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time/category/${this.category}?column=${this.column}&filters=${this.encodeFilters}&datebucket=${this.dateBucketType}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
    );

  }

  public arraysContainSameObjects<T>(a: T[], b: T[]): boolean {
    // Check if both arrays have the same length
    if (a.length !== b.length) {
      return false;
    }

    // Convert objects to strings for comparison
    const aStrings = a.map(item => JSON.stringify(item));
    const bStrings = b.map(item => JSON.stringify(item));

    // Check if every object in 'a' is present in 'b' and vice versa
    return aStrings.every(item => bStrings.includes(item)) && bStrings.every(item => aStrings.includes(item));
  }

  //saves updates for all settings and are then applied.
  public async createPendingUpdates(action) {
    if ('changeSubCategory' in action) {
      // Find the index of the existing action with the key 'changeSubCategory'
      const index = this.pendingUpdates.findIndex((item) => 'changeSubCategory' in item);

      if (index !== -1) {
        // If the action already exists, replace it with the new action
        this.pendingUpdates.splice(index, 1, action);
      } else {
        // If the action does not exist, push the new action
        this.pendingUpdates.push(action);
      }

    }
    if ('changeAggregate' in action) {
      // Find the index of the existing action with the key 'changeAggregate'
      const index = this.pendingUpdates.findIndex((item) => 'changeAggregate' in item);

      if (index !== -1) {
        // If the action already exists, replace it with the new action
        this.pendingUpdates.splice(index, 1, action);
      } else {
        // If the action does not exist, push the new action
        this.pendingUpdates.push(action);
      }
    }
    if ('changeDateBucket' in action) {
      // Find the index of the existing action with the key 'changeDateBucket'
      const index = this.pendingUpdates.findIndex((item) => 'changeDateBucket' in item);

      if (index !== -1) {
        // If the action already exists, replace it with the new action
        this.pendingUpdates.splice(index, 1, action);
      } else {
        // If the action does not exist, push the new action
        this.pendingUpdates.push(action);
      }
    }
    if ('filter' in action) {
      if (this.arraysContainSameObjects(this.finalModelFilters, this.modelFilters)) {
        // Find the index of the existing 'filter' action in pendingUpdates
        const filterIndex = this.pendingUpdates.findIndex((pendingAction) => 'filter' in pendingAction);

        // If a 'filter' action exists, remove it from pendingUpdates
        if (filterIndex !== -1) {
          this.pendingUpdates.splice(filterIndex, 1);
        }
      } else {
        // Check if a 'filter' action already exists in pendingUpdates
        const filterIndex = this.pendingUpdates.findIndex((pendingAction) => 'filter' in pendingAction);

        // If a 'filter' action does not exist, add the new action to pendingUpdates
        if (filterIndex === -1) {
          this.pendingUpdates.push(action);
        } else {
          // If a 'filter' action already exists, you might want to update it instead of adding a new one
          // This depends on your application logic
          this.pendingUpdates[filterIndex] = action;
        }
      }
    }

  }

  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );

      if (conversationColumn) {
        console.log("Found conversation column:", conversationColumn);
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public startNewRenameProcess(name: string) {
    this.oldClusterName = name;
    this.editValue = name;
    this.showEditField = true;
  }

  public async updateClusterName() {
    console.log(this.column, typeof this.column);
    let categoryType: string = "";

    if (this.column === "-1") {
      categoryType = "main_cluster";
    } else if (this.column === "-2") {
      categoryType = "sub_cluster";
    } else {
      console.log("HERE");
      return;
    }

    this.clusterError = null;
    this.loadingButton = true;

    await api
      .updateClusterName(
        this.token,
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        categoryType,
        this.oldClusterName,
        this.editValue,
      )
      .then((r) => {
        this.loadingButton = false;
        this.showEditField = false;
      })
      .catch((error) => {
        this.clusterError = error.response.data.detail;
        this.loadingButton = false;
        console.log("error when getting chosen dataset", error);
      });

    await this.getEffectByCategory();

    await this.getSubCategoryPreviewForDashboard();
  }

  public formatDate(dateString: string) {
    const date = new Date(dateString);
    if (this.selectedDateZoomIn !== "all") {
      // If we have selected a specific date with zoom in, we format as "yyyy-MM-dd"
      return format(date, "yyyy-MM-dd");
    } else {
      if (this.dateBucketType === "month") {
        // Format as "yyyy-MM"
        return format(date, "yyyy-MM");
      } else if (this.dateBucketType === "week") {
        // Format as "yyyy-WeekNumber"
        return format(date, "yyyy-MM-dd");
      } else {
        // If dateBucketType is neither 'month' nor 'week', return the original date string
        return dateString;
      }
    }
  }

  public switchDateBucket(value: string) {
    const action = {
      changeDateBucket: {dateBucketType: value, selectedDateZoomIn: "all"}
    };

    this.createPendingUpdates(action);
  }

  public async switchPreviewDate(date: string) {
    this.selectedDatePreview = date;

    this.page = 1;
    await this.getEffectByCategory();

    this.getSubCategoryPreviewForDashboard();
  }

  public historicalColumn(tag) {
    //const result = Object.entries(this.dataGraph.historicalColumn.YearMonth)
      // .map(([id, period]) => ({
      //   period: period as any,
      //   countOfLabelDiff: this.dataGraph.historicalColumn.countOfLabel_diff[id.toString()],
      //   name: this.dataGraph.historicalColumn.chosenColumn[id.toString()],
      //   countOfLabel: this.dataGraph.historicalColumn.countOfLabel[id.toString()],
      //   meanSpent: this.dataGraph.historicalColumn[tag][id.toString()],
      //   meanSpent_diff: this.dataGraph.historicalColumn[tag + "_diff"][id.toString()],
      //   meanSpentPctDiff: this.dataGraph.historicalColumn[tag + "_pct_diff"][id.toString()],
      // }))
    const result = Object.entries(this.dataGraph.historicalColumn.YearMonth)
    .map(([id, period]) => {
      // Base object with properties that are always included
      const baseObject = {
        period: period as any,
        countOfLabelDiff: this.dataGraph.historicalColumn.countOfLabel_diff[id.toString()],
        countOfLabelPctDiff: this.dataGraph.historicalColumn.countOfLabel_pct_diff[id.toString()],
        countOfLabel: this.dataGraph.historicalColumn.countOfLabel[id.toString()],
        name: this.dataGraph.historicalColumn.chosenColumn[id.toString()],
      };

      // Conditional properties based on the value of 'tag'
      const conditionalProperties = tag !== 'countOfLabel' && tag !== 'countOfLabelDiff' ? {
        meanSpent: this.dataGraph.historicalColumn[tag][id.toString()],
        meanSpent_diff: this.dataGraph.historicalColumn[tag + "_diff"][id.toString()],
        meanSpentPctDiff: this.dataGraph.historicalColumn[tag + "_pct_diff"][id.toString()],
      } : {};

      // Combine the base object with the conditional properties
      return {
        ...baseObject,
        ...conditionalProperties,
      };
    })
      .filter((item) => {
        // item.meanSpent_diff !== 0 && item.countOfLabelDiff !== 0
          if (item.name !== "Not assigned") {
            // Create a new Date object
            const date = new Date(item.period);
            // Format the date as "YYYY-MM"
            let formattedDate;
            if (this.dateBucketType === "month" && this.selectedDateZoomIn === "all") {
              formattedDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2);
              // Compare with selectedDate
            } else if (this.dateBucketType === "week") {
              formattedDate = format(date, "yyyy-MM-dd");
            } else {
              formattedDate = format(date, "yyyy-MM-dd");
            }

            if (this.selectedDate !== "all") {
              return formattedDate === this.selectedDate;
            } else {
              return true;
            }
          }
      })
      .sort((a, b) => {
      // Apply different sorting logic based on the value of 'tag'
      if (tag !== 'countOfLabel' && tag !== 'countOfLabelDiff') {
        return Math.abs(b[tag + "PctDiff"] * b.countOfLabelDiff) - Math.abs(a[tag + "PctDiff"] * a.countOfLabelDiff);
      } else if (tag === 'countOfLabel') {
        return Math.abs(b['countOfLabelDiff']) - Math.abs(a['countOfLabelDiff']);
      } else {
        // Assuming 'countOfLabelDiff' is a property that should be weighted by 'countOfLabel'
        const weightedDiffA = a['countOfLabelDiff'] * a['countOfLabel'];
        const weightedDiffB = b['countOfLabelDiff'] * b['countOfLabel'];
        return Math.abs(weightedDiffB) - Math.abs(weightedDiffA);
      }
    });
    return result;
  }

  get reversedTimeStamps() {
    const reversed: any[] = [...this.allTimeStamps].reverse();
    reversed.unshift("all");
    return reversed;
  }

  get reversedTimeStampsFilter() {
    if (!this.dataGraph) {
      return [];
    }

    const uniqueArray: string[] = [
      ...new Set(Object.values(this.dataGraph.reliance.YearMonth)),
    ].map(String);
    const dates = uniqueArray.map((date) => new Date(date));
    dates.sort((a, b) => a.getTime() - b.getTime());
    let timeline: any = [];

    if (this.selectedDateZoomIn === "all") {
      if (this.dateBucketType === "month") {
        const currentDate = new Date(uniqueArray[0]);
        while (currentDate <= dates[dates.length - 1]) {
          timeline.push(new Date(currentDate).toISOString().substr(0, 7));
          currentDate.setMonth(currentDate.getMonth() + 1);
        }
        // mapping a slice of the first 7 tokens to each item in uniqueArray
        timeline = timeline.map((item: string) => item.slice(0, 7));
      } else if (this.dateBucketType === "week") {
        let currentDate = new Date(uniqueArray[0]);

        while (currentDate <= endOfWeek(dates[dates.length - 1])) {
          timeline.push(format(currentDate, "yyyy-MM-dd"));
          currentDate = addWeeks(currentDate, 1);
        }
      }
    } else {
      uniqueArray.forEach((date) => {
        const currentDate = new Date(date);
        const formattedDate = currentDate.toISOString().split("T")[0]; // Format to 'yyyy-MM-dd'

        // Check if the formatted date is not already in the timeline
        if (!timeline.includes(formattedDate)) {
          timeline.push(formattedDate);
        }
      });

      timeline.reverse();
      timeline.unshift("all");
      return timeline;
    }

    const reversed: any[] = [...this.allTimeStamps].reverse();
    reversed.unshift("all");
    return reversed;
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  get getModelAndLabels() {
    if (this.pickedModelAndLabels.model === -1) {
      return {
        modelName: "unknown",
        labels: [],
      };
    }

    const pickedModel = this.connectedModels.find(
      (model) => model.id === this.pickedModelAndLabels.model,
    );

    if (!pickedModel) {
      throw new Error(`Model with id ${this.pickedModelAndLabels.model} not found`);
    }

    const labels = pickedModel.label_containers
      .flatMap((container) => container.labels)
      .filter((label) => this.pickedModelAndLabels.labels.includes(label.id));

    return {
      modelName: pickedModel.name,
      labels,
    };
  }

  public doesFilterAlreadyExist(column, modelId) {
    return this.tempModelFilters.some(
      (filter: any) => filter.column_id === column && filter.inherited_from === modelId,
    );
  }

  public createTempModelFilters() {
    this.tempModelFilters = this.modelFilters.slice();

    const filter: IModelFilterLabel = {
      filter_type: "label",
      label_id: this.pickedModelAndLabels.labels,
      inherited_from: this.pickedModelAndLabels.model,
      label_container: this.pickedModelAndLabels.label_container,
    };

    this.tempModelFilters.push(filter);
  }

  public inherit(filter: any) {
    this.tempModelFilters.push(filter);
  }

  get getModelFiltersOfPickedModel() {
    const modelo = this.connectedModels.find((m) => m.id === this.pickedModelAndLabels.model);

    // If a matching model is found, return its filters
    if (modelo) {
      const datasetModelFilter = modelo.filters.filters.find(
        (m) => m.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
      if (datasetModelFilter) {
        return datasetModelFilter.filters;
      }
      return [];
    }

    // If no matching model is found, return []
    return [];
  }

  public deleteTempFilter(index: number) {
    this.tempModelFilters.splice(index, 1);
  }

  public pickModelAndLabel(labelId: number, modelId: number, labelContainerId: number) {
    this.pickedModelAndLabels.model = modelId;
    this.pickedModelAndLabels.labels = [labelId];
    this.pickedModelAndLabels.label_container = labelContainerId;
  }

  get previewRows() {
    return this.rows.slice(1);
  }

  get tableDataHeader() {
    const rows: any[] = [];
    this.columns.forEach((column, key) => {
      const text = "";
      rows.push({
        text: this.rows[0][column.value] + text,
        value: column.value,
        align: "left",
      });
    });
    return rows;
  }

  public async chooseDataset() {
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        true,
      )
      .then((r) => {
        console.log(r.data);
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        // this.headDialog = true;
        this.rowsToShow = this.rows;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.connectedModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public getModelById(id: number) {
    return this.connectedModels.find((m) => m.id === id);
  }

  get encodeFilters() {
    const filtersString = JSON.stringify(this.modelFilters);
    return btoa(filtersString);
  }

  public applyFilter() {
    this.dialogFilter = false;

    const action = {
      filter: true
    };

    this.createPendingUpdates(action);
  }

  public expandRow(index) {
    console.log("index", index);
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }

  public createCombined(data) {
    let preDefinedFilters: any[] = [];
    let datasetFilter;
    if (this.model && this.model.filters && this.model.filters.filters) {
      datasetFilter = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    if (datasetFilter && datasetFilter.filters.length > 0) {
      preDefinedFilters = datasetFilter.filters.map((item) => ({
        ...item,
        on_model: true,
      }));
    }
    const pickedFilters: any[] = data.map((item) => ({
      ...item,
      combined: item.column_id ? item.column_id : item.inherited_from,
      on_model: false,
    }));

    return [...pickedFilters, ...preDefinedFilters];
  }

  public deleteFilter(index: number) {
    this.modelFilters.splice(index, 1);
    this.applyFilter();
  }

  public getFilterColumnName(id) {
    if (this.rows.length > 0) {
      const columnIndex = `column_${id}`;
      const row = this.rows[0];
      if (row.hasOwnProperty(columnIndex)) {
        return row[columnIndex];
      }
    } else {
      return "None";
    }
  }

  public toggleFilter() {
    const allPresentInPickedValues = this.columnInformation.column_values.every((obj) =>
      this.pickedValues.includes(obj.value),
    );
    if (!allPresentInPickedValues) {
      this.columnInformation.column_values.forEach((obj) => {
        if (!this.pickedValues.includes(obj.value)) {
          this.pickedValues.push(obj.value);
        }
      });
    } else {
      this.pickedValues = [];
    }
  }

  public async getFilterCount(datasetFilter = true) {
    console.log("getFilterCount", this.pickedValues);

    this.loadingButton = true;  
    this.filterError = null;
    let allFilters = this.modelFilters.slice();
    if (datasetFilter === true) {
      if (this.filterType !== "timestamp") {
        let tempPick: (string | number)[]  = [];

        this.pickedValues.forEach((obj) => {
          tempPick.push(obj.value);
        });
        this.pickedValues = tempPick;
        
        const filter: IModelFilterString = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          filter_values: this.pickedValues,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
        };

        allFilters.push(filter);
      } else {
        const filter: IModelFilterTimestamp = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
          start_date: this.startDate,
          end_date: this.endDate,
        };

        allFilters.push(filter);
      }
    } else {
      allFilters = this.tempModelFilters.slice();
    }

    // Add the pre-existing model filters
    let preExistingFilters;
    if (this.model && this.model.filters && this.model.filters.filters) {
      preExistingFilters = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    if (preExistingFilters && preExistingFilters.filters.length > 0) {
      allFilters.push(...preExistingFilters.filters);
    }

    await api
      .getFilterCount(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        allFilters,
      )
      .then((r) => {
        this.loadingButton = false;
        this.totalItems = r.data.total_items;
        this.d6 = 3;
      })
      .catch((error) => {
        console.log("filterError", error.response);
        this.loadingButton = false;
        this.filterError = error.response;
      });
  }

  public async filterColumnValues() {
    this.loadingButton = true;
    this.filterError = null;

    const allFilters = this.modelFilters.slice();

    // Add the pre-existing model filters
    let preExistingFilters;
    if (this.model && this.model.filters && this.model.filters.filters) {
      preExistingFilters = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    if (preExistingFilters && preExistingFilters.filters.length > 0) {
      allFilters.push(...preExistingFilters.filters);
    }

    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.recommendationFilter,
        allFilters,
      )
      .then((r) => {
        this.columnInformation = r.data;
        this.loadingButton = false;
        this.d6 = 2;
        if (this.columnInformation.dtype === "timestamp") {
          this.filterType = "timestamp";
        } else {
          this.filterType = "must";
        }
        console.log("kolla", r.data, typeof this.columnInformation);
      })
      .catch((error) => {
        console.log("filterError", error.response);
        this.loadingButton = false;
        this.filterError = error.response;
        this.columnInformation = {} as IColumnInformation;
      });
  }

  public addModelFilter() {
    if (this.filterType !== "timestamp") {
      const filter: IModelFilterString = {
        column_id: parseInt(this.columnInformation.column_id, 10),
        store_column_name: this.columnInformation.store_column_name,
        filter_values: this.pickedValues,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
      };
      this.modelFilters.push(filter);
    } else {
      const filter: IModelFilterTimestamp = {
        column_id: parseInt(this.columnInformation.column_id, 10),
        store_column_name: this.columnInformation.store_column_name,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      this.modelFilters.push(filter);
    }
  }

  public goBackFilter() {
    if (this.filterDataset) {
      if (this.d6 === 1) {
        this.d6 = 0;
      } else if (this.d6 === 2) {
        this.d6 = 1;
        this.pickedValues = [];
        this.startDate = "";
        this.endDate = "";
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    } else {
      if (this.d6 === 1) {
        this.d6 = 0;
        this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
      } else if (this.d6 === 2) {
        this.tempModelFilters = [];
        this.expandedRowModel = null;
        this.d6 = 1;
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    }
  }

  public nextStepFilter() {
    console.log(this.d6);
    if (this.filterDataset) {
      if (this.d6 === 1) {
        this.filterColumnValues();
      } else if (this.d6 === 2) {
        this.getFilterCount();
      } else if (this.d6 === 3) {
        this.addModelFilter();
        this.applyFilter();
        this.closeFilterDialog();
      }
    } else {
      if (this.d6 === 0) {
        this.d6 = 1;
      } else if (this.d6 === 1) {
        this.d6 = 2;
        this.createTempModelFilters();
      } else if (this.d6 === 2) {
        this.getFilterCount(false);
      } else if (this.d6 === 3) {
        this.modelFilters = this.tempModelFilters.slice();
        this.applyFilter();
        this.closeFilterDialog();
      }
    }
  }

  public closeFilterDialog() {
    this.dialogFilter = false;
    this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
    this.d6 = 0;
    this.recommendationFilter = -1;
    this.filterDataset = true;
    this.filterError = null;
    this.filterType = "must";
    this.columnInformation = {} as IColumnInformation;
    this.pickedValues = [];
    this.showAll = false;
    this.endDate = "";
    this.startDate = "";
    this.tempModelFilters = [];
    this.expandedRowModel = null;
  }

  get activeRecommendationFilter() {
    if (this.recommendationFilter > -1) {
      return this.columns[this.recommendationFilter].value;
    } else {
      return "None";
    }
  }

  public isThisTextColumn(key: number) {
    const datasetWithMetaData = this.datasets.filter(
      (dataset) => dataset.id === parseInt(this.$router.currentRoute.params.id, 10),
    )[0];
    const keyString = String(key); // Convert the key to a string

    if (datasetWithMetaData.meta_data.columns.hasOwnProperty(keyString)) {
      const column = datasetWithMetaData.meta_data.columns[keyString];

      if (column.hasOwnProperty("special_column") && column.special_column === "text") {
        console.log(`Column ${keyString} has special_column = "text"`);
        return true;
      }
    }

    return false;
  }

  public highlightFilter(key, value) {
    let newColumn = this.recommendationFilter;

    // checks model filters
    let datasetFilter;
    if (this.model && this.model.filters && this.model.filters.filters) {
      datasetFilter = this.model.filters.filters.find(
        (item) => item.dataset_id === parseInt(this.$router.currentRoute.params.id, 10),
      );
    }

    let alreadyFilter: boolean = false;
    if (
      datasetFilter &&
      datasetFilter.filters.length > 0 &&
      datasetFilter.filters.find((item) => item.column_id === key)
    ) {
      alreadyFilter = true;
    }

    // Checks column and added filters
    if (Number(key) || Number(key) === 0) {
      if (
        !this.isThisTextColumn(key) &&
        this.modelFilters.filter((filter) => filter.column_id === key).length === 0 &&
        !alreadyFilter
      ) {
        newColumn = Number(key);
      }
    }

    if (this.recommendationFilter === newColumn) {
      this.recommendationFilter = -1;
    } else {
      this.recommendationFilter = newColumn;
    }
  }

  get multiplier() {
    if (this.time === "minutes") {
      return 60;
    } else if (this.time === "hours") {
      return 60 * 60;
    } else if (this.time === "days") {
      return 60 * 60 * 24;
    } else {
      return 1;
    }
  }

  public filteredSubCategoryPreview(subCategory) {
    const result = this.subCategoryPreviewItems[subCategory];
    if (result === undefined) {
      return [];
    }
    return [result];
  }

  public checkAllowedDate(date) {
    return this.allTimeStamps.includes(date);
  }

  public setTime(time) {
    this.time = time;
    if (this.time === "minutes") {
      this.decimalPoints = 0;
    } else {
      this.decimalPoints = 2;
    }
  }

  public async getSubCategoryPreviewForDashboard(forcePageOne: boolean = true) {
    const subCategories: any = [];
    this.effectCategoryColumnsPaginated.forEach((item) => {
      let page: number = 1;
      if (forcePageOne) {
        page = 1;
      } else {
        const subCatItem = this.filteredSubCategoryPreview(item.subCategory)[0];
        if (subCatItem !== undefined) {
          page = subCatItem!.page || 1;
        }
      }

      const subCategoryWithPage = {
        name: item.subCategory,
        page,
      };
      subCategories.push(subCategoryWithPage);
    });

    const datePreview: string = this.selectedDatePreview;

    const previewParameters: IPreviewParametersSubCategoriesDate = {
      category: this.category,
      sub_categories: subCategories,
      date: datePreview,
    };
    // const subCategories: any = [];
    // this.effectCategoryColumnsPaginated.forEach((item) => {
    //   subCategories.push(item.subCategory);
    // });

    // const previewParameters: IPreviewParametersSubCategories = {
    //   category: this.category,
    //   sub_categories: subCategories,
    // };
    this.loadingClusterPreview = true;
    // this.loadingEffectByCategory = true;
    await api
      .getColumnPreviewForTimeDashboard(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.modelid, 10),
        previewParameters,
        this.startDate,
        this.endDate,
        this.column,
        this.finalModelFilters,
        this.dateBucketType,
        this.selectedDateZoomIn,
      )
      .then((response) => {
        this.subCategoryPreviewItems = response.data;
        this.loadingClusterPreview = false;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
        this.dashboardItemsError = error.response;
        this.loadingClusterPreview = false;
      });
  }

  get crumbs() {
    return [
      {
        text: this.model!.name,
        disabled: true,
        menu: false,
      },
      {
        text: "Graph overview",
        disabled: false,
        href: `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time?column=${this.column}&filters=${this.encodeFilters}&datebucket=${this.dateBucketType}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
        menu: false,
      },
      {
        text: "",
        disabled: false,
        menu: true,
      },
    ];
  }

  get paginationLength() {
    if (this.effectCategoryColumns && this.effectCategoryColumns.tot) {
      return Math.ceil(this.effectCategoryColumns.tot.length / 10);
    } else {
      return 0;
    }
  }

  public sorteffectCategoryColumns(tag: string) {

    this.effectCategoryColumns.tot.sort((a, b) => {
        // Push entries with 'Not assigned' to the end
        if(a.subCategory=== 'Not assigned') return 1; // 'a' goes last
        if(b.subCategory=== 'Not assigned') return -1; // 'b' goes last

        // Assuming 'tag' refers to numeric values for sorting
        // For numeric values, ensure parsing or handle non-numeric sorting differently
        let aValue = Number(a[tag]);
        let bValue = Number(b[tag]);
        
        // For ascending order, reverse the comparison
        return bValue - aValue; 
    });
    this.getSubCategoryPreviewForDashboard();
  }

  get effectCategoryColumnsPaginated() {
    return this.effectCategoryColumns.tot.slice((this.page - 1) * 10, this.page * 10);
  }

  public async beforeRouteUpdate(to, from, next) {
    this.tabModel = 1;
    this.SubkeyToShow = "countOfLabel";
    this.keyToShow = "countOfLabel";
    this.historicalKeyColumn = "countOfLabel";
    this.endDate = "";
    this.startDate = "";
    this.dataGraph = false;
    this.effectCategoryColumns = [];
    this.page = 1;
    await this.getCategoryData();
    this.selectedDatePreview = this.reversedTimeStamps[0];
    this.selectedDate = this.reversedTimeStamps[0];
    await this.getEffectByCategory();
    await this.getSubCategoryPreviewForDashboard();

    next();
  }

  public async getEffectByCategory() {
    this.dashboardItemsError = "";
    this.loadingEffectByCategory = true;

    const datePreview: string = this.selectedDatePreview;

    await api
      .getEffectByCategoryTime(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.modelid, 10),
        this.category,
        this.startDate,
        this.endDate,
        this.column,
        this.finalModelFilters,
        this.dateBucketType,
        datePreview,
        this.aggregateConversations,
        this.selectedDateZoomIn,
      )
      .then((response) => {
        this.effectCategoryColumns = response.data;
        this.sorteffectCategoryColumns("countOfLabel");
        this.loadingEffectByCategory = false;
        console.log("effect", this.effectCategoryColumns);
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
        this.dashboardItemsError = error.response;
        this.loadingEffectByCategory = false;
      });
  }

  public changeCategory(labelObj) {
    if (labelObj === undefined) {
      return;
    }
    if (labelObj.id === this.category) {
      return;
    }
    this.loading = true;
    this.category = labelObj.id;
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${this.$router.currentRoute.params.modelid}/time/category/${this.category}?column=${this.column}&filters=${this.encodeFilters}&datebucket=${this.dateBucketType}&aggregate=${this.aggregateConversations}&zoomin=${this.selectedDateZoomIn}`,
    );
  }

  get categoryName() {
    if (this.category === -1 || this.labels.length === 0) {
      return "";
    }
    const categoryName = this.labels.filter((label) => label.id === this.category);
    return categoryName[0].name;
  }

  get maxAndMinDate() {
    let minDate: any = "";
    let maxDate: any = "";

    if (this.allTimeStamps.length > 0) {
      minDate = new Date(Math.min(...this.allTimeStamps.map((date) => new Date(date).getTime())));
      maxDate = new Date(Math.max(...this.allTimeStamps.map((date) => new Date(date).getTime())));
      // safety set to first day of month
      maxDate.setDate(1);
      minDate.setDate(1);
      maxDate.toISOString().slice(0, 10);
      minDate.toISOString().slice(0, 10);
    }
    return { minDate, maxDate };
  }

  public getFirstDayOfMonth(dateStr: string): string {
    // Create a new Date object from the date string
    const date = new Date(dateStr);

    // Set the day of the month to the 1st
    date.setDate(1);

    // Format the date back to a string in the format "yyyy-mm-dd"
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading 0 if necessary
    return `${year}-${month}`;
  }

  public checkAllowedDateStart(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() + 1);

    const allTimestampsRollbacked = this.allTimeStamps.map(this.getFirstDayOfMonth);

    if (
      allTimestampsRollbacked.includes(date) &&
      currentDate <= new Date(this.maxAndMinDate.maxDate)
    ) {
      if (this.endDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const endDateObj: Date = new Date(this.endDate);
        const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (endDateObj >= currentDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public checkAllowedDateEnd(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() - 1);

    const allTimestampsRollbacked = this.allTimeStamps.map(this.getFirstDayOfMonth);

    if (
      allTimestampsRollbacked.includes(date) &&
      currentDate >= new Date(this.maxAndMinDate.minDate)
    ) {
      if (this.startDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const startDateObj: Date = new Date(this.startDate);
        const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() - 1);
        if (currentDate >= startDateObj) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public async getCategoryData() {
    this.graphError = "";
    this.loading = true;
    await api
      .getCategoryGraphsTime(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(this.$router.currentRoute.params.modelid, 10),
        this.category,
        this.startDate,
        this.endDate,
        this.column,
        this.finalModelFilters,
        this.dateBucketType,
        this.aggregateConversations,
        this.selectedDateZoomIn,
      )
      .then((response) => {
        this.dataGraph = response.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
        this.graphError = error.response;
        this.loading = false;
      });
  }

  get allTimeStamps() {
    if (!this.dataGraph.timestamps) {
      return [];
    }

    const uniqueArray: string[] = [...new Set(Object.values(this.dataGraph.timestamps))].map(
      String,
    );
    const dates = uniqueArray.map((date) => new Date(date));
    dates.sort((a, b) => a.getTime() - b.getTime());
    let timeline: any = [];

    if (this.dateBucketType === "month") {
      const currentDate = new Date(uniqueArray[0]);
      while (currentDate <= dates[dates.length - 1]) {
        const year = currentDate.getFullYear();
        // getMonth returns 0-11, so add 1 for the correct month number and pad with a leading zero if necessary
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const formattedDate = `${year}-${month}`;
        timeline.push(formattedDate);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      // mapping a slice of the first 7 tokens to each item in uniqueArray
      timeline = timeline.map((item: string) => item.slice(0, 7));
    } else if (this.dateBucketType === "week") {
      let currentDate = new Date(uniqueArray[0]);

      while (currentDate <= endOfWeek(dates[dates.length - 1])) {
        timeline.push(format(currentDate, "yyyy-MM-dd"));
        currentDate = addWeeks(currentDate, 1);
      }
    }

    return timeline;
  }

  // free inferences left
  public async getSubscription() {
    await api
      .getSubscription(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.subscribedToPlan = r.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  // ==================

  public async mounted() {
    if (this.$route.query.column === undefined) {
      this.column = -1;
    } else {
      const temp: any = this.$route.query.column;
      this.column = parseInt(temp, 10);
    }

    const encodedFilters = this.$route.query.filters;

    try {
      if (encodedFilters !== undefined && typeof encodedFilters === "string") {
        const filtersString = atob(encodedFilters);
        const filters = JSON.parse(filtersString);
        console.log("decoded", filters);
        this.finalModelFilters = filters;
      }
    } catch (error) {
      console.log("Error while decoding filter:", error);
    }

    const dateBucket = this.$route.query.datebucket;

    if (dateBucket !== undefined && typeof dateBucket === "string") {
      this.dateBucketType = dateBucket;
    }

    const aggregateConversations = this.$route.query.aggregate;

    if (aggregateConversations !== undefined) {
      // Convert the string to a boolean
      this.aggregateConversations = aggregateConversations === "true";
    }

    const zoomInDate = this.$route.query.zoomin;

    if (zoomInDate !== undefined && typeof zoomInDate === "string") {
      this.selectedDateZoomIn = zoomInDate;
    }

    this.loading = true;
    this.category = parseInt(this.$router.currentRoute.params.categoryid, 10);
    this.chooseDataset();
    await this.getDatasetConnectedModels();

    await this.getCategoryData();
    console.log(this.dataGraph);
    this.selectedDatePreview = this.reversedTimeStamps[0];
    this.selectedDate = this.reversedTimeStamps[0];
    await this.getEffectByCategory();
    await this.getSubCategoryPreviewForDashboard();
  }

  get filteredLabels() {
    return this.labels.filter((label) => label.id !== this.category);
  }

  get token() {
    return readToken(this.$store);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.modelid);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.modelid);
  }
}
