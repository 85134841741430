
import { readToken } from "@/store/main/getters";
import { api } from "@/api";
import { Component, Vue, Watch } from "vue-property-decorator";
import cluster from "cluster";
import { set } from "vue/types/umd";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { readDataset } from "@/store/dataset/getters";

import { IModelFilterString } from "@/interfaces";

@Component({
  components: {
    LottieAnimation,
  },
})
export default class YourComponent extends Vue {
  public clusters: any = [];
  public currentPage: number = 1;
  public itemsPerPage: number = 100;
  public subClustersCache: Record<number, any[]> = {};
  public totalClusterSize: number = 1; // placeholder so that we do not divide with 0
  public loadingSubClusters: boolean = false;
  public datasetFilters: Array<any> = [];
  public metadataOptions: Array<any> = [];
  public systemColumns: any = {};
  public speakerColumnValues: any = {};
  public speedSpeakerToggle: string = "all";
  public dialog: boolean = false;
  public selectedCluster: any = {};
  public sortOptions: Array<string> = ["CSAT", "DSAT", "Volume"];
  public chosenSort: string = "Volume";

  public async mounted() {
    await this.getAllClusters();
    await this.loadSubclustersForCurrentPage();
    await this.getSystemColumns();
    this.createDatasetColumns();
    this.getSpeakerValues();
  }

  public async getPageSubclusters(clusterIds: Array<number>) {
    for (const clusterId of clusterIds) {
      await this.getSubClusters(clusterId);
    }
  }

  public async getSystemColumns() {
    await api
      .getSystemColumns(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.systemColumns = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  public async getSubClusters(mainClusterId: number) {
    // Check if sub clusters are already cached
    if (this.subClustersCache[mainClusterId]) {
      return this.subClustersCache[mainClusterId];
    } else {
      // Fetch sub clusters from API if not cached
      const response = await api.getSubClusters(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        mainClusterId,
      );

      const subClusters = response.data["sub_clusters"];
      // Update cache
      this.$set(this.subClustersCache, mainClusterId, subClusters);
    }
  }

  public async getSubClustersForManyMain(clusterIds: Array<number>) {
    // Check if sub clusters are already cached
    const uncachedClusterIds = clusterIds.filter((id) => !this.subClustersCache[id]);
    // Fetch sub clusters from API if not cached
    await api
      .getSubClustersForManyMain(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        uncachedClusterIds,
      )
      .then((response) => {
        for (let tmpCluster of response.data["clusters"]) {
          this.$set(
            this.subClustersCache,
            tmpCluster["main_cluster_id"],
            this.sortSubClusters(tmpCluster["sub_clusters"]),
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public toggleSort(sortString: string) {
    this.chosenSort = sortString;
    this.sortMainClusters(this.clusters);
    this.loadingSubClusters = true;
    this.loadSubclustersForCurrentPage();
  }

  public async speakerToggle(speaker: string) {
    // copied from search.vue
    // get the column with default values
    const column = this.metadataOptions.filter((column) => column.name === "speaker");

    // we need to check if the column is selected as filter already. If thats the case, remove it.
    this.datasetFilters.forEach((filter) => {
      if (filter.store_column_name === "speaker") {
        this.datasetFilters.splice(this.datasetFilters.indexOf(filter), 1);
      }
    });
    if (speaker !== "all") {
      const filter: IModelFilterString = {
        column_id: column[0].key,
        store_column_name: column[0].name,
        filter_values: [speaker],
        dtype: "keyword",
        filter_type: "must",
      };

      this.datasetFilters.push(filter);
    }

    // update the clusters
    this.loadingSubClusters = true;
    await this.getAllClusters();
    await this.loadSubclustersForCurrentPage();
  }

  public expandDialog(item: any) {
    this.dialog = true;
    this.selectedCluster = item;
  }

  public truncateTitle(title: string) {
    return title.length > 70 ? title.substring(0, 70) + "..." : title;
  }

  public truncateDescription(description: string) {
    return description.length > 300 ? description.substring(0, 300) + "..." : description;
  }

  public createDatasetColumns() {
    // copied from search.vue
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      this.metadataOptions = [];
      Object.keys(this.dataset.meta_data.columns).forEach((columnKey) => {
        this.metadataOptions.push({
          key: columnKey,
          ...this.dataset!.meta_data.columns[columnKey],
        });
      });

      Object.keys(this.systemColumns.columns).forEach((columnKey) => {
        this.metadataOptions.push({
          key: columnKey,
          ...this.systemColumns.columns[columnKey],
        });
      });
    } else {
      this.metadataOptions = [];
    }
  }

  public formatNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  public routeToCluster(mainClusterName: string, subClusterName: string) {
    console.log("Subcluster name is: ", subClusterName);
    if (subClusterName !== "") {
      window.open(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${parseInt(
          this.$router.currentRoute.params.id,
          10,
        )}/dashboard/search?main_cluster=${encodeURIComponent(
          mainClusterName,
        )}&sub_cluster=${encodeURIComponent(subClusterName)}&speaker=${encodeURIComponent(
          this.speedSpeakerToggle,
        )}`,
        "_blank",
      );
    } else {
      window.open(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${parseInt(
          this.$router.currentRoute.params.id,
          10,
        )}/dashboard/search?main_cluster=${encodeURIComponent(
          mainClusterName,
        )}&speaker=${encodeURIComponent(this.speedSpeakerToggle)}`,
        "_blank",
      );
    }
  }

  public sortMainClusters(clusters) {
    if (this.chosenSort === "DSAT") {
      // Pop clusters that are named "Not assigned" and save them to another array
      const notAssignedClusters = clusters.filter((cluster) => cluster.name === "Not assigned");
      var sortedClusters = clusters.filter((cluster) => cluster.name !== "Not assigned");
      sortedClusters.sort((a, b) => {
        return b.totalDSAT - a.totalDSAT;
      });
      // Push the "Not assigned" clusters back to the end of the array
      sortedClusters.push(...notAssignedClusters);
      this.clusters = sortedClusters;
    } else if (this.chosenSort === "CSAT") {
      // Pop clusters that are named "Not assigned" and save them to another array
      const notAssignedClusters = clusters.filter((cluster) => cluster.name === "Not assigned");
      var sortedClusters = clusters.filter((cluster) => cluster.name !== "Not assigned");
      sortedClusters.sort((a, b) => {
        return b.totalCSAT - a.totalCSAT;
      });
      // Push the "Not assigned" clusters back to the end of the array
      sortedClusters.push(...notAssignedClusters);
      this.clusters = sortedClusters;
    } else {
      // Pop clusters that are named "Not assigned" and save them to another array
      const notAssignedClusters = clusters.filter((cluster) => cluster.name === "Not assigned");
      var sortedClusters = clusters.filter((cluster) => cluster.name !== "Not assigned");
      sortedClusters.sort((a, b) => {
        return b.doc_count - a.doc_count;
      });
      // Push the "Not assigned" clusters back to the end of the array
      sortedClusters.push(...notAssignedClusters);
      this.clusters = sortedClusters;
    }
  }

  public sortSubClusters(clusters) {
    if (typeof clusters[0].totalDSAT === "number") {
      // Pop clusters that are named "Not assigned" and save them to another array
      const notAssignedClusters = clusters.filter((cluster) => cluster.name === "Not assigned");
      var sortedClusters = clusters.filter((cluster) => cluster.name !== "Not assigned");
      sortedClusters.sort((a, b) => {
        return b.totalDSAT - a.totalDSAT;
      });
      // Push the "Not assigned" clusters back to the end of the array
      sortedClusters.push(...notAssignedClusters);
      return sortedClusters;
    } else {
      // Pop clusters that are named "Not assigned" and save them to another array
      const notAssignedClusters = clusters.filter((cluster) => cluster.name === "Not assigned");
      var sortedClusters = clusters.filter((cluster) => cluster.name !== "Not assigned");
      sortedClusters.sort((a, b) => {
        return b.doc_count - a.doc_count;
      });
      // Push the "Not assigned" clusters back to the end of the array
      sortedClusters.push(...notAssignedClusters);
      return sortedClusters;
    }
  }

  public async getAllClusters() {
    // Your API call here
    const hasSpeaker = this.hasSpeakerProperty ? true : false;
    await api
      .getAllMainClusters(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        [...this.datasetFilters],
        hasSpeaker,
      )
      .then((response) => {
        this.sortMainClusters(response.data["main_clusters"]);
        let totalClusterSize = 0;
        this.clusters.forEach((cluster) => {
          totalClusterSize += cluster.doc_count;

          this.totalClusterSize = totalClusterSize;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public specialColumnMapper = {
    speaker: "speaker",
    filename: "filename",
  };

  public async getSpeakerValues() {
    // Handle the selection change if needed
    const column = this.metadataOptions.find(
      (column) => column.name === this.specialColumnMapper.speaker,
    );
    if (!column) {
      return;
    }
    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        parseInt(column.key),
        [],
      )
      .then((r) => {
        this.speakerColumnValues = r.data;
      })
      .catch((error) => {
        this.speakerColumnValues = {};
        console.log("filterError", error.response);
      });
  }

  get hasSpeakerProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const speakerColumn = columnsArray.find((column) => column.name === "speaker");

      if (speakerColumn) {
        return speakerColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get hasCSATproperties() {
    if (this.clusters && this.clusters[0] && typeof this.clusters[0].totalCSAT === "number") {
      return true;
    } else {
      return false;
    }
  }

  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );

      if (conversationColumn) {
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // public async expandSubcluster(clusterId: number) {

  //   if (clusterId in this.subClustersCache) {
  //     this.$set(this.expand, clusterId, !this.expand[clusterId]);
  //     return
  //   }

  //   await this.getSubClusters(clusterId);
  //   this.$set(this.expand, clusterId, !this.expand[clusterId]);
  // }

  get paginatedClusters() {
    this.loadingSubClusters = true;
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.clusters.slice(startIndex, endIndex);
  }

  public async loadSubclustersForCurrentPage() {
    const clusterIds = this.paginatedClusters.map((cluster) => cluster.cluster_id);
    await this.getSubClustersForManyMain(clusterIds);
    this.loadingSubClusters = false;
  }

  get dataset() {
    // copied from search.vue
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  @Watch("currentPage")
  public onPageChange() {
    this.loadSubclustersForCurrentPage();
  }

  get token() {
    return readToken(this.$store);
  }
}
