
import { Component, Vue, Prop, Emit, Watch} from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class TimeBarChart extends Vue {
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public dataGraph!: any;
  @Prop({ default: {} })
  public labelsByIds!: any;
  @Prop({ default: {} })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  @Prop({ default: "meanSpent" })
  public keyToShow!: any;
  @Prop({ default: "minutes" })
  public startTime!: string;
  public time: string = "minutes";

  public keyTranslator =
    {
      countOfLabel: "Volume",
      meanSpent: "Mean agent workload",
      meanResponse: "Mean response time",
      meanResolve: "Mean customer workload",
      totalResponse: "Total response time",
      totalResolve: "Total customer workload",
      totalSpent: "Total agent workload",
    };

  public handlePlotClick(data) {
    this.focuslabel(
      this.labelsByName[data.points[0].x].id,
    );
  }


  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelAutomaticColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }

  @Watch('startTime')
  public onNewTimeChanged(newVal: string, oldVal: string) {
    // Update the 'time' variable here
    this.time = newVal;
  }

  public async mounted() {
    // this.time = this.startTime;
  }


  get multiplier() {
    if (this.time === "minutes") {
      return 60;
    } else if (this.time === "hours") {
      return 60 * 60;
    } else if (this.time === "days") {
      return 60 * 60 * 24;
    } else {
      return 1;
    }
  }
  get predictionCountList() {
    const res: number[] = [];
    const names: string[] = [];
    const perc: number[] = [];
    this.labels.forEach((v, k) => {
      names.push(v.name);
      let keyWithValueB: any;
      for (const key in this.dataGraph.category["label text"]) {
        if (this.dataGraph.category["label text"][key] === v.name) {
          keyWithValueB = key;
          break;
        }
      }
      let result: any = [];
      let formattedResult: any = 0;
      if (this.keyToShow !== 'countOfLabel') {
        result = this.dataGraph.category[this.keyToShow][keyWithValueB] / (this.multiplier);
        formattedResult = parseFloat(result.toFixed(2));
      } else {
        formattedResult = this.dataGraph.category[this.keyToShow][keyWithValueB];
      }
      res.push(formattedResult);
    });

    if(this.keyToShow === 'countOfLabel') {
      res.forEach((v, k) => {
        perc.push(parseFloat((v / res.reduce((a, b) => a + b, 0) * 100).toFixed(1)));
      });
      return [res, names, perc];
    } else {
      return [res, names];
    }
  }

  get labelAutomaticBarData() {
    if (this.keyToShow === 'countOfLabel') {
      return {
        cliponaxis: false,
        x: this.predictionCountList[1],
        y: this.predictionCountList[0],
        type: "bar",
        hoverinfo: 'text+percent',
        textposition: "outside",
        text: this.predictionCountList[0].map((yValue, i) => {
          const percentage = this.predictionCountList[2][i]; // Get the corresponding percentage value
          return `${yValue} (${percentage}%)`; // Construct the string
        }),
        colorscale: "Viridis",
        marker: {
          color: this.labelAutomaticColorList,
        },
      };
    } else {
      return {
        cliponaxis: false,
        x: this.predictionCountList[1],
        y: this.predictionCountList[0],
        type: "bar",
        hoverinfo: 'text+percent',
        textposition: "outside",
        text: this.predictionCountList[0],
        colorscale: "Viridis",
        marker: {
          color: this.labelAutomaticColorList,
        },
      };
    }
    }

  get labelBarLayout() {
    if (this.keyToShow !== 'countOfLabel') {
      return {
        margin: { l: 50, r: 50, t: 50, b: 50 },
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        barmode: "stack",
        xaxis: {
          type: 'category',
          automargin: true,
          showgrid: true,
          zeroline: false,
          showline: true,
          gridcolor: "#ffffff",
          linecolor: "#ffffff",
          gridwidth: 1,
        },
        yaxis: {
          title: {
            text: this.keyTranslator[this.keyToShow] + " (" + this.time + ")",
          },

          showgrid: true,
          zeroline: false,
          gridcolor: "#ffffff",
          linecolor: "#ffffff",
          gridwidth: 1,
          showline: true,
        },
      };
    } else {
      return {
        margin: { l: 50, r: 50, t: 50, b: 50 },
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        barmode: "stack",
        xaxis: {
          type: 'category',
          automargin: true,
          showgrid: true,
          zeroline: false,
          showline: true,
          gridcolor: "#ffffff",
          linecolor: "#ffffff",
          gridwidth: 1,
        },
        yaxis: {
          title: {
            text: this.keyTranslator[this.keyToShow],
          },

          showgrid: true,
          zeroline: false,
          gridcolor: "#ffffff",
          linecolor: "#ffffff",
          gridwidth: 1,
          showline: true,
        },
      };
    }
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.labels.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k.id);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }
  @Emit()
  public focuslabel(clickedLabel) {
  }
}

