
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class ClusterPieChart extends Vue {
  @Prop({ default: false })
  public subCategories!: any[];
  @Prop({ default: false })
  public labelName!: number;


  public async mounted() {
  }

  @Emit()
  public handleclick(clickedCluster) {}

  public handlePlotClick(data) {
    // console.log(data.points[0].label)
    // let index = data.points[0].data.labels.indexOf(data.points[0].label);
    // let clusterId = data.points[0].data.metadata[index]
    // this.handleclick(clusterId);
  }


  get parseByCategory() {
    const ids: any = [];
    const values: any = [];
    const combined: any = [];
    this.subCategories.forEach((perLabel: any) => {
      if (perLabel.label === this.labelName) {
        perLabel.subCategories.forEach((columnObject: any) => {
          if (columnObject.count > 0) {
            values.push(columnObject.count);
            combined.push(columnObject.name + " (" + columnObject.count + ")");
          }
        });
      }
    });
    return [ids, values, combined];
  }

  get clusterData() {
    return [
      {
        values: this.parseByCategory[1],
        labels: this.parseByCategory[2],
        type: "pie",
        hole: .4,
        textposition: "inside",
        hoverinfo: 'label+percent',
      },
    ];
  }

}
