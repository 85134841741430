
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
  IWorkspaceCreate,
} from "@/interfaces";
import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import { dispatchCreateWorkspace } from "@/store/main/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
  },
})
export default class CreateLabel extends Vue {
  public id: number = 1;
  public dialogLabel: boolean = true;
  public labelName: string = "";
  public labelExamples: string[] = [];
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeLabelExample: string = "";
  public loading: boolean = false;
  public e6: number = 0;

  public color: string = "green";
  public colors: string[] = [
    "red",
    "purple",
    "pink",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  get isAdmin() {
    return readHasAdminAccess;
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public changeColor(color) {
  }

  get remainingColors() {
    let remainingColors: string[] = [];
    if (this.labels) {
      this.colors.forEach((c) => {
        const colorExists = this.labels!.filter((label) => label.color === c);
        if (colorExists.length === 0) {
          remainingColors.push(c);
        }
      });
    } else {
      remainingColors = this.colors;
    }

    this.color = remainingColors[0];
    return remainingColors;
  }

  get labelNameExists(): boolean {
    if (this.labels) {
      return this.labels!.filter((label) => label.name === this.labelName).length > 0;
    }
    return false;
  }

  get labelNameErrors(): string[] {
    if (this.labelNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelIsClean(): boolean {
    if (this.labelName.length < 1 || this.labelName.length > 40) {
      return false;
    }
    if (this.labelNameExists) {
      return false;
    }
    return true;
  }

  public async createLabelAPI() {
    const newLabel: IWorkspaceCreate = {
      name: this.labelName,
    };
    await dispatchCreateWorkspace(this.$store, {
      data: newLabel,
    }).then(
      (r) => {
        this.labelName = "";
        this.$router.push({
          path: `/main/${this.$router.currentRoute.params.workspaceid}/`,
        });
      },
      (reason) => {
        // console.warning(reason);
      },
    );
  }
}
