
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetOriginal,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readDatasets, readDataset } from "@/store/dataset/getters";

import {
  readToken,
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
} from "@/store/main/getters";
import { api } from "@/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import { ISubscription } from "@/interfaces";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ModelCard from "@/components/ModelCard.vue";
import ConversationBrowser from "@/components/ConversationBrowser.vue";
import colors from "vuetify/es5/util/colors";

import CSATBarChart from "@/components/CSATBarChart.vue";
import { nextTick } from "vue/types/umd";

@Component({
  components: {
    ItemPreview,
    CSATBarChart,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
    ConversationBrowser,
  },
})
export default class BrowseView extends Vue {
  public conversationId: any = null;
  public start: any = null;
  public end: any = null;

  public async mounted() {
    if (this.$route.query.conversation_id !== undefined) {
      this.conversationId = this.$route.query.conversation_id;
    }

    if (this.$route.query.start !== undefined) {
      if (typeof this.$route.query.start === 'string') {
        this.start = parseFloat(this.$route.query.start);
      }
    }

    if (this.$route.query.end !== undefined) {
      if (typeof this.$route.query.end === 'string') {
        this.end = parseFloat(this.$route.query.end);
      }
    }
    console.log("start", typeof this.start)
    console.log("conversationId", this.conversationId);
  }
}
