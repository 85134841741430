
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

import { readToken } from "@/store/main/getters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import colors from "vuetify/es5/util/colors";
import { api } from "@/api";
import { readModel, readFirstLabelContainer, readLabels, readAllLabelsByWorkspaceId } from "@/store/model/getters";

@Component
export default class TimeGraphSettings extends Vue {
  @Prop({ default: "minutes" }) readonly propTime: string;
  @Prop({ default: false }) readonly propHasConversationProperty: boolean;
  @Prop({ default: false }) readonly propAggregateConversations: boolean;
  @Prop({ default: "month" }) readonly propDateBucketType: string;

  // Local data properties initialized from the props

  public localTime: string;
  public localHasConversationProperty: boolean;
  public localAggregateConversations: boolean;
  public localDateBucketType: string;

  data() {
    return {
      localTime: this.propTime,
      localHasConversationProperty: this.propHasConversationProperty,
      localAggregateConversations: this.propAggregateConversations,
      localDateBucketType: this.propDateBucketType,
    };
  }
  // Emit the changes instead of mutating the props
  public setTime(time: string) {
    this.localTime = time;
    this.$emit('update:time', time);
  }

  public switchAggregate(aggregate: boolean) {
    this.localAggregateConversations = aggregate;
    this.$emit('update:aggregate', aggregate);
  }

  public switchDateBucket(dateBucket: string) {
    this.localDateBucketType = dateBucket;
    this.$emit('update:datebucket', dateBucket);
  }
}
