
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";
import * as Plotly from 'vue-plotly';

@Component
export default class TimeSeries extends Vue {
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public dataGraph!: any;
  @Prop({ default: {} })
  public labelsByIds!: any;
  @Prop({ default: {} })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  @Prop({ default: "countOfLabel" })
  public keyToShow!: any;
  @Prop({ default: "meanStars" })
  public SubkeyToShow!: any;
  @Prop({ default: false })
  public categoryName!: any;

  public showTimeline = true;
  public showStacked = true;

  public subKeyTranslator =
    {
      meanStars: "Mean stars",
      countOfLabel: "Volume",
      countOfCSAT: "CSAT volume",
      effectcsat: "CSAT impact",
      effectdsat: "DSAT impact",
      countOfDSAT: "DSAT volume",
      totalCSAT: "CSAT Score",
      totalDSAT: "DSAT Score",
    };

  public async mounted() {
    if (this.keyToShow === 'main') {
      this.showTimeline = false;
    }
  }

  public handlePlotClick(event) {
  }



  get labelCountList() {
    const res: number[] = [];
    this.labels.forEach((k) => {
      res.push(k.counter);
    });
    return res;
  }
  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelAutomaticColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }
  get labelManualColorList() {
    const res: string[] = [];
    this.labels.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(k.color)].base));
    });
    return res;
  }
  get predictionCountList() {
    const res: number[] = [];
    this.labels.forEach((v, k) => {
      res.push(this.dataGraph.total[this.keyToShow][v.name]);
    });
    return res;
  }

  get timeSeriesData() {
    if (this.keyToShow === 'countcategory') {
      return [{
        x: this.dataGraph.reliance.YearMonth,
        y: this.dataGraph.reliance.countOfLabel,
        type: "scatter",
        line: {
          color: this.labelsByName[this.categoryName].color,
          width: 3,
        },
        name: this.categoryName + " volume",
        lineshape: "spline",
        mode: "lines+markers",
      },
      {
        x: this.dataGraph.reliance.YearMonth,
        y: this.dataGraph.reliance.rolling,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'countcluster') {
      return [{
        x: this.dataGraph.reliance.YearMonth,
        y: this.dataGraph.reliance.countOfLabel,
        type: "scatter",
        line: {
          width: 3,
        },
        name: this.categoryName + " volume",
        lineshape: "spline",
        mode: "lines+markers",
      },
      {
        x: this.dataGraph.reliance.YearMonth,
        y: this.dataGraph.reliance.rolling,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'csatcategory') {
      return [{
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.totalCSAT,
        type: "scatter",
        line: {
          color: this.labelsByName[this.categoryName].color,
          width: 3,
        },
        name: this.categoryName + " CSAT score",
        lineshape: "spline",
        mode: "lines+markers",
        fill: 'tozeroy',
      },
      {
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.rolling,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'dsatcategory') {
      return [{
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.totalDSAT,
        type: "scatter",
        line: {
          color: this.labelsByName[this.categoryName].color,
          width: 3,
        },
        name: this.categoryName + " DSAT score",
        lineshape: "spline",
        mode: "lines+markers",
        fill: 'tozeroy',
      },
      {
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.rollingdsat,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'csatcluster') {
      return [{
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.totalCSAT,
        type: "scatter",
        line: {
          width: 3,
        },
        name: this.categoryName + " CSAT score",
        lineshape: "spline",
        mode: "lines+markers",
        fill: 'tozeroy',
      },
      {
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.rolling,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'dsatcluster') {
      return [{
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.totalDSAT,
        type: "scatter",
        line: {
          width: 3,
        },
        name: this.categoryName + " DSAT score",
        lineshape: "spline",
        mode: "lines+markers",
        fill: 'tozeroy',
      },
      {
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.rollingdsat,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'csateffectcategory') {
      return [{
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.effectcsat,
        type: "scatter",
        line: {
          color: this.labelsByName[this.categoryName].color,
          width: 3,
        },
        name: this.categoryName + " CSAT impact",
        lineshape: "spline",
        mode: "lines+markers",
        fill: 'tozeroy',
      },
      {
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.rollingeffect,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'csateffectcluster') {
      return [{
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.effectcsat,
        type: "scatter",
        line: {
          width: 3,
        }, // this.labelsByName[this.categoryName].color,
        name: this.categoryName + " CSAT impact",
        lineshape: "spline",
        mode: "lines+markers",
        fill: 'tozeroy',
      },
      {
        x: this.dataGraph.relianceCSAT.YearMonth,
        y: this.dataGraph.relianceCSAT.rollingeffect,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'subcategorybreakdown') {
      const groups: any = {};
      for (let i = 0; i < Object.keys(this.dataGraph.monthly.chosenColumn).length; i++) {
        const name = this.brFyText(this.dataGraph.monthly.chosenColumn[i]);

        if (!groups[name]) {
          groups[name] = { x: [], y: [], name, type: 'lines', line: { width: 3, color: this.colorFallback(name)}};
        }

        if (this.dataGraph.monthly[this.SubkeyToShow][i] !== 0) {
          groups[name].x.push(this.dataGraph.monthly.YearMonth[i]);
          let result: any = [];
          let formattedResult: any = 0;
          result = this.dataGraph.monthly[this.SubkeyToShow][i];
          formattedResult = parseFloat(result.toFixed(2));

          groups[name].y.push(formattedResult);
        }
      }
      // Create traces from groups
      const traces: any[] = [];
      for (const name in groups) {
        if (groups.hasOwnProperty(name)) {
          traces.push(groups[name]);
        }
      }

      traces.sort((a, b) => {
        const maxA = Math.max(...a.y);
        const maxB = Math.max(...b.y);
        return maxB - maxA;
      });

      return traces;
    } else if (this.keyToShow === 'main') {
      // console.log(Object.keys(this.dataGraph.monthly['label text']).length)

      const groups: any = {};
      for (let i = 0; i < Object.keys(this.dataGraph.monthly['label text']).length; i++) {
        const name = this.dataGraph.monthly['label text'][i];

        if (!groups[name]) {
          if (this.SubkeyToShow === "countOfLabel" && this.showStacked) {
            groups[name] = { x: [], y: [], groupnorm: 'percent', name, line:
            { width: 3, color: this.hexToRGBA(colors[this.snakeToCamel(this.labelsByName[name].color)].base) },
            stackgroup: 'one', text: [], hoverinfo: 'name+text+x', hovertemplate: '' };
          } else {
            groups[name] = { x: [], y: [], name, type: 'lines', line:
            { width: 3, color: this.hexToRGBA(colors[this.snakeToCamel(this.labelsByName[name].color)].base) } };
          }
        }
        if (this.dataGraph.monthly[this.SubkeyToShow][i] !== 0) {
          groups[name].x.push(this.dataGraph.monthly.YearMonth[i]);
          groups[name].y.push(this.dataGraph.monthly[this.SubkeyToShow][i]);
          if (this.SubkeyToShow === "countOfLabel" && this.showStacked) {
            groups[name].text.push(this.dataGraph.monthly[this.SubkeyToShow][i]);
          }
        }
      }
      if (this.SubkeyToShow === "totalCSAT") {
        groups["Total CSAT"] = { x: [], y: [], name: "Total CSAT",
        type: 'lines', line: { width: 4, dash: 'dashdot', color: 'green' } };
        for (let i = 0; i < Object.keys(this.dataGraph.monthly_agg.YearMonth).length; i++) {
          groups["Total CSAT"].x.push(this.dataGraph.monthly_agg.YearMonth[i]);
          groups["Total CSAT"].y.push(this.dataGraph.monthly_agg.totalCSAT[i]);
        }
      }
      if (this.SubkeyToShow === "totalDSAT") {
        groups["Total DSAT"] = { x: [], y: [], name: "Total DSAT",
        type: 'lines', line: { width: 4, dash: 'dashdot', color: 'red' } };
        for (let i = 0; i < Object.keys(this.dataGraph.monthly_agg.YearMonth).length; i++) {
          groups["Total DSAT"].x.push(this.dataGraph.monthly_agg.YearMonth[i]);
          groups["Total DSAT"].y.push(this.dataGraph.monthly_agg.totalDSAT[i]);
        }
      }
      // Create traces from groups
      const traces: any[] = [];
      for (const name in groups) {
        if (groups.hasOwnProperty(name)) {
          traces.push(groups[name]);
        }
      }

      traces.sort((a, b) => {
        const maxA = Math.max(...a.y);
        const maxB = Math.max(...b.y);
        return maxB - maxA;
      });

      console.log("THIS IS TRACES", traces)


      return traces;
    } else if (this.keyToShow === 'subcategorytime') {
      const groups: any = {};
      for (let i = 0; i < this.dataGraph.cluster_time.chosenColumn.length; i++) {
        const name = this.dataGraph.cluster_time.chosenColumn[i];
        if (!groups[name]) {
          groups[name] = {
            x: [],
            y: [],
            name,
            type: 'lines',
            line: { width: 3 },
          };
        }
        if (this.dataGraph.cluster_time.countOfLabel[i] !== 0) {
          groups[name].x.push(this.dataGraph.cluster_time.YearMonth[i]);
          groups[name].y.push(this.dataGraph.cluster_time.countOfLabel[i]);
        }
      }

      const traces: any[] = Object.values(groups);

      console.log(traces);

      traces.sort((a, b) => {
        const maxA = Math.max(...a.y);
        const maxB = Math.max(...b.y);
        return maxB - maxA;
      });

      console.log(traces);
      return traces;
    }

  }

  get timeSeriesLayout() {
    if (this.keyToShow === 'countcategory') {
      return {
        title: 'Volume by Year and Month',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Year and Month',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'Volume',
        },
      };
    } else if (this.keyToShow === 'dsatcategory') {
      return {
        title: 'DSAT by Year and Month',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Year and Month',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'DSAT',
        },
      };
    } else if (this.keyToShow === 'countcluster') {
      return {
        title: 'Total Count by Year and Month',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Year and Month',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'Total Count',
        },
      };
    } else if (this.keyToShow === 'csatcategory') {
      return {
        title: 'CSAT by Year and Month',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Year and Month',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'CSAT',
        },
      };
    } else if (this.keyToShow === 'csateffectcategory') {
      return {
        title: 'CSAT impact by Year and Month',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Year and Month',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'CSAT impact',
        },
      };
    } else if (this.keyToShow === 'subcategorytime') {
      return {
        traceorder: 'grouped',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Year and Month',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          type: 'linear',
          title: 'Volume',
        },
      };
    } else if (this.keyToShow === 'main' || this.keyToShow === 'subcategorybreakdown') {
      if (this.showTimeline) {
        return {
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
          xaxis: {
            rangebreaks: [
              { enabled: true, bounds: ["2010-03", "2027-05"] },
              { enabled: true, bounds: ["2010-03", "2027-05"] },
            ],
            title: 'Year and Month',
            autorange: true,
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: '1m',
                  step: 'month',
                  stepmode: 'backward',
                },
                {
                  count: 6,
                  label: '6m',
                  step: 'month',
                  stepmode: 'backward',
                },
                { step: 'all' },
              ],
            },
            rangeslider: { range: [] },
            type: 'date',
          },
          yaxis: {
            type: 'linear',
            title: this.subKeyTranslator[this.SubkeyToShow],
          },
        };
      } else {
        return {
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
          xaxis: {
            title: 'Year and Month',
            rangebreaks: [
              { enabled: true, bounds: ["2010-03", "2027-05"] },
              { enabled: true, bounds: ["2010-03", "2027-05"] },
            ],
            autorange: true,
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: '1m',
                  step: 'month',
                  stepmode: 'backward',
                },
                {
                  count: 6,
                  label: '6m',
                  step: 'month',
                  stepmode: 'backward',
                },
                { step: 'all' },
              ],
            },
          },
          yaxis: {
            type: 'linear',
            title: this.subKeyTranslator[this.SubkeyToShow],
          },
        };
      }
    }
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.labels.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k.id);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }

  public colorFallback(name) {
    // this.hexToRGBA(colors[this.snakeToCamel("grey")].base)
    return this.labelsByName[name] === undefined ?
    null :
    this.hexToRGBA(colors[this.snakeToCamel(this.labelsByName[name].color)].base);
  }

  public brFyText(input: string) {
    let output = '';
    for (let i = 0; i < input.length; i++) {
      if (i % 30 === 0 && i !== 0) {
        output += '<br>';
      }
      output += input.charAt(i);
    }
    return output;
  }


  @Emit()
  public focuslabel(clickedLabel) {
  }
}
