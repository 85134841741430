
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IInferencePrompt, IInferencePromptUpdate } from "@/interfaces";
import { readInferencePrompts, readInferencePrompt } from "@/store/dataset/getters";
import {
  dispatchUpdateInferencePrompt,
  dispatchDeleteInferencePrompt,
} from "@/store/dataset/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";
import { api } from "@/api";
import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import { readToken } from "@/store/main/getters";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
  },
})
export default class CreateLabel extends Vue {
  public id: number = 1;
  public dialogLabel: boolean = true;
  public loading: boolean = false;
  public dialogRemove: boolean = false;
  public redirected: boolean = false;
  public uploadError: any = null;
  public promptName: string = "";
  public prompt: string = "";
  public activeInferencePromptId = -1;
  public loadingModel: boolean = false;
  public generativeModels: any[] = [{ name: "Default model", description: "Default generative model" , "status": "trained"}];
  public selectedModel: any = null;


  get isAdmin() {
    return readHasAdminAccess;
  }

  get token() {
    return readToken(this.$store);
  }


  public async getGenerativeModels() {
    this.loadingModel = true;
    await api
      .getGenerativeModels(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        console.log("RESPONSE", r.data);

        // extend the list with r.data
        this.generativeModels = this.generativeModels.concat(r.data);

        this.loadingModel = false;
        // this.generativeModels = Array(20).fill(this.generativeModels).flat();

        //
      })
      .catch((error) => {
        console.log("error when getting generative models", error);
      });
  }

  get inferencePrompt() {
    return readInferencePrompt(this.$store)(+this.$router.currentRoute.params.inferencepromptid);
  }

  get inferencePrompts() {
    return readInferencePrompts(this.$store);
  }

  public changeColor(color) {}

  public async deleteInferencePrompt() {
    this.uploadError = "";
    this.loading = true;
    await dispatchDeleteInferencePrompt(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: parseInt(this.$router.currentRoute.params.id, 10),
      inferencePromptId: this.inferencePrompt!.id,
    })
      .then((r) => {
        this.pushRouter();
        this.loading = false;
        this.dialogRemove = false;
      })
      .catch((error) => {
        this.dialogRemove = false;
        this.loading = false;
        this.uploadError = error.response;
      });
  }

  get promptNameErrors(): string[] {
    if (!this.promptNameIsClean && !this.loading) {
      return ["Prompt is too long or too short"];
    }
    return [];
  }

  get promptErrors(): string[] {
    if (!this.promptIsClean && !this.loading) {
      return ["Prompt is too long or too short"];
    }
    return [];
  }

  get promptNameIsClean(): boolean {
    if (this.promptName.length < 1 || this.promptName.length > 20) {
      return false;
    }
    return true;
  }

  get promptIsClean(): boolean {
    if (this.prompt.length < 1 || this.prompt.length > 5000) {
      return false;
    }
    return true;
  }

  get modelSelected(): boolean {
    return this.selectedModel !== null;
  }

  public async updateInferencePrompt() {
    this.uploadError = "";

    const updatedInferencePrompt: IInferencePromptUpdate = {
      name: this.promptName,
      prompt: this.prompt,
      model_id: this.generativeModels[this.selectedModel].id ?? null,
    };

    this.loading = true;
    await dispatchUpdateInferencePrompt(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: parseInt(this.$router.currentRoute.params.id, 10),
      inferencePromptId: this.inferencePrompt!.id,
      inferencePrompt: updatedInferencePrompt,
    })
      .then((r) => {
        this.dialogLabel = false;
        this.promptName = "";
        this.loading = false;
        this.pushRouter();
      })
      .catch((error) => {
        this.dialogRemove = false;
        this.loading = false;
        this.uploadError = error.response;
      });
  }
  public created() {
    this.activeInferencePromptId = +this.$router.currentRoute.params.inferencepromptid;
  }


  public getGenModelFromId(id: number) {

    return this.generativeModels.find((model) => model.id === id);
  }


  public async mounted() {
    await this.getGenerativeModels();
    if (this.inferencePrompts!.length) {
      this.promptName = this.inferencePrompt!.name;
      this.prompt = this.inferencePrompt!.prompt;
      if (this.inferencePrompt!.model_id) {
        this.selectedModel = this.generativeModels.findIndex((model) => model.id === this.inferencePrompt!.model_id);

    } else {
      this.selectedModel = 0;
    }
      
    }
    // redirected = we were redirected from dashboard
    if (this.$route.query.redirected === "true") {
      this.redirected = true;
    }
  }

  public pushRouter() {
    if (this.redirected === false) {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/settings`,
      );
    } else {
      this.$router.push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/settings`,
      });
    }
  }
}
