
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class CategoryPieChart extends Vue {
  @Prop({ default: false })
  public data!: any[];
  @Prop({ default: false })
  public labelName!: number;
  @Prop({ default: false })
  public subCategoryName!: any;


  public async mounted() {
  }

  public handleclick(clickedCluster) {}

  public handlePlotClick(data) {
    console.log("Not implemented!");
  }

  get parse() {
    console.log("kolla", this.data);
    const labelNames: any = [];
    const values: any = [];
    const combined: any = [];
    this.data.forEach((label: any) => {
      const subCategory = label.subCategories.filter(
        (c) => c.name === this.subCategoryName);
      if (subCategory.length > 0) {
        labelNames.push(label.label + " (" + subCategory[0].count + ")");
        values.push(subCategory[0].count);
      }
    });
    return [labelNames, values];
  }

  get categoryData() {
    console.log(this.parse);
    return [
      {
        values: this.parse[1],
        labels: this.parse[0],
        type: "pie",
        hole: .4,
        textposition: "inside",
        hoverinfo: 'label+percent',
      },
    ];
  }

}
