
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

import { readToken } from "@/store/main/getters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import colors from "vuetify/es5/util/colors";
import { api } from "@/api";
import { readModel, readFirstLabelContainer, readLabels, readAllLabelsByWorkspaceId } from "@/store/model/getters";

@Component
export default class SubCategoryPicker extends Vue {
  @Prop({ default: {} })
  public dataset!: any;
  @Prop({ default: 0 })
  readonly propColumn: number;

  public metadataOptions: any[] = [];
  public selectedColumn: any = {}
  public systemColumns: any = {}
  public localColumn: string;

  public async mounted() {
    await this.getSystemColumns();
    this.createDatasetColumns();
    console.log(this.metadataOptions);
    console.log(this.localColumn, typeof this.localColumn);
  }

  get getColumnName() {
    const matchingObject = this.metadataOptions.find(
      (option) => option.key === this.localColumn
    );

    const result = matchingObject ? matchingObject.name : "None";
    return result;
  }

  data() {
    return {
      localColumn: this.propColumn.toString(),
    };
  }

  public closeSelect() {
    // This will cause the v-select to lose focus and close the dropdown

  }

  get token() {
    return readToken(this.$store);
  }

  public async getSystemColumns() {
    await api
      .getSystemColumns(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      )
      .then((r) => {
        this.systemColumns = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public createDatasetColumns() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {

      this.metadataOptions = [];
      Object.keys(this.dataset.meta_data.columns).forEach((columnKey)=> {
            this.metadataOptions.push({
              key: columnKey,
              ...this.dataset!.meta_data.columns[columnKey]
            });
        });

      Object.keys(this.systemColumns.columns).forEach((columnKey)=>{
        if (columnKey !== "-3") {
        this.metadataOptions.push({
          key: columnKey,
          ...this.systemColumns.columns[columnKey]
        });
      }
      });

    } else {
      this.metadataOptions = [];
    }
  }

  public switchColumn(item: any) {
    this.localColumn = item.key;
    this.$emit('changecolumn', parseInt(item.key, 10));
  }
}
