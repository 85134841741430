
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetRecommendation,
} from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
  },
})
export default class CreateLabel extends Vue {
  public id: number = 1;
  public dialogLabel: boolean = true;
  public labelDescription: string = "";
  public labelName: string = "";
  public labelExamples: string[] = [];
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeLabelExample: string = "";
  public loading: boolean = false;
  public e6: number = 0;
  public uploadError: any = null;
  public multi: boolean = false;
  public redirected: boolean = false;

  public color: string = "green";
  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  public async mounted() {
    // store params if we were redirected
    if (this.$route.query.multi === "true") {
      this.multi = true;
    }
    // redirected = we were redirected from model settings
    if (this.$route.query.redirected === "true") {
      this.redirected = true;
    }
  }
  get isAdmin() {
    return readHasAdminAccess;
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }
  public sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public changeColor(color) {}

  get colorList() {
    if (this.remainingColors.length > 0) {
      this.color = this.remainingColors[0];
      return this.remainingColors;
    } else {
      this.color = this.colors[Math.floor(Math.random() * this.color.length)];
      return this.colors;
    }
  }

  get remainingColors() {
    let remainingColors: string[] = [];
    if (this.labels) {
      this.colors.forEach((c) => {
        const colorExists = this.labels!.filter((label) => label.color === c);
        if (colorExists.length === 0) {
          remainingColors.push(c);
        }
      });
    } else {
      remainingColors = this.colors;
    }

    return remainingColors;
  }

  get labelNameExists(): boolean {
    if (this.labels) {
      return this.labels!.filter((label) => label.name === this.labelName).length > 0;
    }
    return false;
  }

  get labelNameErrors(): string[] {
    if (this.labelNameExists && !this.loading) {
      return ["This name does already exist"];
    } else if (this.labelName.length > 40) {
      return ["Label name is too long"];
    }
    return [];
  }

  get labelDescriptionErrors(): string[] {
    if (this.labelDescription.length > 250) {
      return ["Label description is too long"];
    }
    return [];
  }

  get labelIsClean(): boolean {
    if (
      this.labelName.length < 1 ||
      this.labelName.length > 40 ||
      this.labelDescription.length > 250
    ) {
      return false;
    }
    if (this.labelNameExists) {
      return false;
    }
    return true;
  }

  public async createLabelAPI() {
    if (this.activeLabelExample.length) {
      this.labelExamples.push(this.activeLabelExample);
    }
    this.activeLabelExample = "";
    console.log("Label description", this.labelDescription);
    const newLabel: ILabelCreate = {
      name: this.labelName,
      description: this.labelDescription,
      color: this.color,
      examples: this.labelExamples,
    };
    this.loading = true;
    await dispatchCreateLabel(this.$store, {
      labelContainerId: this.firstLabelContainer!.id,
      label: newLabel,
    })
      .then((r) => {
        setTimeout(() => {
          const ids: number[] = this.labels.map((l) => {
            return l.id;
          });
          const going = Math.max(...ids);
          this.dialogLabel = false;
          this.labelName = "";
          this.labelDescription = "";
          this.uploadError = null;
          this.labelExamples = [];
          this.loading = false;
          this.pushRouter();
        }, 200);
      })
      .catch((uploadError) => {
        this.loading = false;
        console.log("UploadError", uploadError);
        this.uploadError = uploadError.response;
      });
  }

  public pushRouter() {
    if (this.redirected === false) {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/label/`,
      );
    } else {
      this.$router.push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${
          this.$router.currentRoute.params.id
        }/dashboard/labelcontainers/${this.firstLabelContainer!.id}`,
      });
    }
  }

  public async getModels() {
    await dispatchGetModels(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }

  public removeLabelExample(toRemove) {
    let tmpExamples = this.labelExamples.slice();
    tmpExamples = tmpExamples.filter((example) => example !== toRemove);
    this.labelExamples = tmpExamples;
  }

  public addLabelExample() {
    if (this.activeLabelExample === "") {
      this.createLabelAPI();
    } else if (!this.labelExamples.includes(this.activeLabelExample)) {
      this.labelExamples.push(this.activeLabelExample);
      this.activeLabelExample = "";
    }
  }
}
