
import { Component, Vue, Prop, Emit, Watch} from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";

@Component
export default class TimeBarChartCategory extends Vue {
  @Prop({ default: false })
  public dataGraph!: any;
  @Prop({ default: false })
  public effectCategoryColumns!: any;
  @Prop({ default: "meanSpent" })
  public keyToShow!: any;
  @Prop({ default: "minutes" })
  public startTime!: string;
  public time: string = "minutes";

  public currentPage: number = 1;
  public itemsPerPage: number = 70;
  public totalItems: number = 0;
  public totalPages: number = 0;
  public maxValue: any = 10;

  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  public keyTranslator =
    {
      countOfLabel: "Volume",
      meanSpent: "Mean agent workload",
      meanResponse: "Mean response time",
      meanResolve: "Mean customer workload",
      totalResponse: "Total response time",
      totalResolve: "Total customer workload",
      totalSpent: "Total agent workload",
    };

  public handlePlotClick(data) {
    this.focuscategory(
      //this.labelsByName[data.points[0].x].id,
      1,
    );
  }

  @Watch('startTime')
  public onNewTimeChanged(newVal: string, oldVal: string) {
    // Update the 'time' variable here
    this.time = newVal;
  }

  public async mounted() {
  }


  get multiplier() {
    if (this.time === "minutes") {
      return 60;
    } else if (this.time === "hours") {
      return 60 * 60;
    } else if (this.time === "days") {
      return 60 * 60 * 24;
    } else {
      return 1;
    }
  }

  get predictionCountList() {
    const res: number[] = [];
    const names: string[] = [];
    const perc: number[] = [];
    let namesWithValues: any = {};
    let addValue: number = 0;

    // Assuming effectCCategoryColumns.tot is available in this context
    const tot = this.effectCategoryColumns.tot;

    tot.forEach((item, i) => {
      const n = item['subCategory'];
        if (!Object.keys(namesWithValues).includes(n)) {
          namesWithValues[n] = item[this.keyToShow];
        }
    });

    this.maxValue = Math.max(...Object.values(namesWithValues) as number[]);
    this.keyToShow !== 'countOfLabel' ? this.maxValue = this.maxValue/this.multiplier : this.maxValue = this.maxValue;
    this.totalItems = Object.keys(namesWithValues).length;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

    // Convert namesWithValues to an array of [key, value] pairs, then sort by value in descending order
    const sortedEntries = Object.entries(namesWithValues).sort((a: any, b: any) => b[1] - a[1]);

    // Pagination logic
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Apply pagination to the sortedEntries
    const paginatedEntries = sortedEntries.slice(startIndex, endIndex);

    // Now iterate over the paginated entries instead of sortedEntries
    paginatedEntries.forEach(([k, v]: [any, any]) => {
      // Calculate percentage
      const total = sortedEntries.reduce((sum: number, [, value]: [any, any]) => sum + value, 0);
      const percentage = parseFloat((v / (total + addValue) * 100).toFixed(1));
      
      // Push values into respective arrays
      if (k === "Not assigned") {
        console.log("SKIP")
      } else {
        perc.push(percentage);
        res.push(this.keyToShow === 'countOfLabel'?  v : v/this.multiplier);
        names.push(k);
      }
    });
    return [res, names, perc];

    // if (this.keyToShow === 'countOfLabel') {
    //   this.dataGraph.monthly.chosenColumn.forEach((n, i) => {
    //     if (n !== "Not assigned") {
    //       if (!Object.keys(namesWithValues).includes(n)) {
    //         namesWithValues[n] = this.dataGraph.monthly.countOfLabel[i];
    //       } else {
    //         namesWithValues[n] = this.dataGraph.monthly.countOfLabel[i] + namesWithValues[n];
    //       }
    //     } else {
    //       if (!Object.keys(namesWithValues).includes(n)) {
    //         namesWithValues[n] = this.dataGraph.monthly.countOfLabel[i];
    //       } else {
    //         namesWithValues[n] = this.dataGraph.monthly.countOfLabel[i] + namesWithValues[n];
    //       }
    //     }
    //   });

    //   this.maxValue = Math.max(...Object.values(namesWithValues) as number[]);
    //   this.totalItems = Object.keys(namesWithValues).length;
    //   this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

    //     // Convert namesWithValues to an array of [key, value] pairs, then sort by value in descending order

    //     const sortedEntries = Object.entries(namesWithValues).sort((a: any, b: any) => b[1] - a[1]);
    //     // Pagination logic
    //     const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    //     const endIndex = startIndex + this.itemsPerPage;

    //     // Apply pagination to the sortedEntries
    //     const paginatedEntries = sortedEntries.slice(startIndex, endIndex);

    //     // Now iterate over the paginated entries instead of sortedEntries
    //     paginatedEntries.forEach(([k, v]: [any, any]) => {
    //       // Calculate percentage
    //       const total = sortedEntries.reduce((sum: number, [, value]: [any, any]) => sum + value, 0);
    //       const percentage = parseFloat((v / (total + addValue) * 100).toFixed(1));
          
    //       // Push values into respective arrays
    //       if (k === "Not assigned") {
    //         console.log("SKIP")
    //       } else {
    //         perc.push(percentage);
    //         res.push(v);
    //         names.push(k);
    //       }
    //     });
    //     return [res, names, perc];
    // } else {
    //   console.log("NOT IMPLEMENTED")
    //   return false;
    // }
  }

  get labelAutomaticBarData() {
      const traces = this.predictionCountList[1].map((categoryName, index) => {
      const yValue = this.predictionCountList[0][index];
      const percentage = this.predictionCountList[2][index];
      
      return {
        name: categoryName,
        x: [categoryName], // X-axis labels are the category names
        y: [yValue], // Y-axis values are the counts or values
        type: "bar",
        hoverinfo: 'text+percent+x',
        text: `${yValue} (${percentage}%)`,
      };
    });

    return traces;
  }

  get labelBarLayout() {
      return {
        showlegend: false, 
        margin: { l: 50, r: 50, t: 50, b: 50 },
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          type: 'category',
          automargin: true,
          showgrid: true,
          zeroline: false,
          showline: true,
          gridcolor: "#ffffff",
          linecolor: "#ffffff",
          gridwidth: 1,
        },
        yaxis: {
          title: {
            text: this.keyTranslator[this.keyToShow],
          },
          showgrid: true,
          zeroline: false,
          gridcolor: "#ffffff",
          linecolor: "#ffffff",
          gridwidth: 1,
          showline: true,
          range: [0, this.maxValue], 
        },
      };
  }

  @Emit()
  public focuscategory(clickedcategory) {
  }
}

