
import { Component, Vue, Prop, Emit, Watch} from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";
import * as Plotly from 'vue-plotly';

@Component
export default class TimeSeries extends Vue {
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public dataGraph!: any;
  @Prop({ default: {} })
  public labelsByIds!: any;
  @Prop({ default: {} })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  @Prop({ default: "countOfLabel" })
  public keyToShow!: any;
  @Prop({ default: "meanStars" })
  public SubkeyToShow!: any;
  @Prop({ default: false })
  public categoryName!: any;
  @Prop({ default: "minutes" })
  public startTime!: string;

  public id: string = this.randomString(10);
  public hasToggled: boolean = true;
  public time: string = "minutes";

  public showTimeline = true;

  public subKeyTranslator =
    {
      countOfLabel: "Volume",
      meanSpent: "Mean agent workload",
      meanResponse: "Mean response time",
      meanResolve: "Mean customer workload",
      totalResponse: "Total response time",
      totalResolve: "Total customer workload",
      totalSpent: "Total agent workload",
      meanChangeVolumeWeighted: "Category Δ weigthed",
      meanChangeVolume: "Category Δ",
      meanChangeVolumePeriod: "Category Δ (WoW)",
      meanChangeVolumeWeightedPeriod: "Category Δ weighted (WoW)",
    };

  public randomString(length: number): string {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = length; i > 0; i--) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
}

public toggleTraceVisibility(id) {
    const traces = this.timeSeriesData; // Access the first trace
    if (traces !== undefined) {
      const trace = traces[id];
      console.log("tr", trace)
      trace.visible = trace.visible === 'legendonly' ? true : 'legendonly';
      this.timeSeriesData![id] = trace;
    }
  }

public ok(event) {
  console.log(event.expandedIndex);
  console.log(event.data[event.expandedIndex]);
  // this.toggleTraceVisibility(event.expandedIndex + 1);
}

  public async mounted() {
    if (this.keyToShow === 'main' || this.keyToShow === 'subcategorybreakdown') {
      this.showTimeline = false;
    }
    this.time = this.startTime;
    console.log(this.id)
  }


  @Watch('startTime')
  public onNewTimeChanged(newVal: string, oldVal: string) {
    // Update the 'time' variable here
    this.time = newVal;
  }

  public brFyText(input: string) {
    let output = '';
    for (let i = 0; i < input.length; i++) {
      if (i % 30 === 0 && i !== 0) {
        output += '<br>';
      }
      output += input.charAt(i);
    }
    return output;
  }


  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }

  get multiplier() {
    if (this.time === "minutes") {
      return 60;
    } else if (this.time === "hours") {
      return 60 * 60;
    } else if (this.time === "days") {
      return 60 * 60 * 24;
    } else {
      return 1;
    }
  }

  get timeSeriesData() {
    if (this.keyToShow === 'subcategorybreakdown') {
      const groups: any = {};
      for (let i = 0; i < Object.keys(this.dataGraph.monthly.chosenColumn).length; i++) {
        const name = this.brFyText(this.dataGraph.monthly.chosenColumn[i]);

        if (!groups[name]) {
          groups[name] = { x: [], y: [], name, hoverlabel: {namelength: -1}, type:'lines', line: { width: 3, color: this.colorFallback(name)}, visible: name === "Not assigned" ? 'legendonly' : true};
        }
        if (this.dataGraph.monthly[this.SubkeyToShow][i] !== 0 && name !== "Not assigned") {
        groups[name].x.push(this.dataGraph.monthly.YearMonth[i]);
        let result: any = [];
        let formattedResult: any = 0;
        if (this.SubkeyToShow !== 'countOfLabel') {
          result = this.dataGraph.monthly[this.SubkeyToShow][i] / this.multiplier;
          formattedResult = parseFloat(result.toFixed(2));
        } else {
          formattedResult = this.dataGraph.monthly[this.SubkeyToShow][i];
        }

        groups[name].y.push(formattedResult);
      }
      }
      // Create traces from groups
      const traces: any[] = [];
      for (const name in groups) {
        if (groups.hasOwnProperty(name)) {
          traces.push(groups[name]);
        }
      }

      console.log("traces", traces)

      traces.sort((a, b) => {
        const maxA = Math.max(...a.y);
        const maxB = Math.max(...b.y);
        return maxB - maxA;
      });

      return traces;
    } else if (this.keyToShow === 'main') {
      const groups: any = {};
      for (let i = 0; i < Object.keys(this.dataGraph.monthly['label text']).length; i++) {
        const name = this.dataGraph.monthly['label text'][i];

        if (!groups[name]) {
          groups[name] = { x: [], y: [], name, type: 'lines', line: { width: 3, color: this.colorFallback(name)}};
        }

        if (this.dataGraph.monthly[this.SubkeyToShow][i] !== 0) {
          groups[name].x.push(this.dataGraph.monthly.YearMonth[i]);
          let result: any = [];
          let formattedResult: any = 0;
          if (this.SubkeyToShow !== 'countOfLabel') {
            result = this.dataGraph.monthly[this.SubkeyToShow][i] / this.multiplier;
            formattedResult = parseFloat(result.toFixed(2));
          } else {
            formattedResult = this.dataGraph.monthly[this.SubkeyToShow][i];
          }

          groups[name].y.push(formattedResult);
      }
      }
      const traces: any[] = [];
      for (const name in groups) {
        if (groups.hasOwnProperty(name)) {
          traces.push(groups[name]);
        }
      }

      return traces;
    } else if (this.keyToShow === 'meanSpent') {
      return [{
        x: this.dataGraph.reliance.YearMonth,
        y: this.multiplied(this.dataGraph.reliance.meanSpent),
        type: "scatter",
        line: {
          width: 3,
        }, // this.labelsByName[this.categoryName].color,
        name: this.categoryName + " mean agent workload",
        lineshape: "spline",
        mode: "lines+markers",
      },
      {
        x: this.dataGraph.reliance.YearMonth,
        y: this.multiplied(this.dataGraph.reliance.rolling),
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'countOfLabel') {
      return [{
        x: this.dataGraph.reliance.YearMonth,
        y: this.dataGraph.reliance.countOfLabel,
        type: "scatter",
        line: {
          width: 3,
        }, // this.labelsByName[this.categoryName].color,
        name: this.categoryName + " volume",
        lineshape: "spline",
        mode: "lines+markers",
      },
      {
        x: this.dataGraph.reliance.YearMonth,
        y: this.dataGraph.reliance.rollingvolume,
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'meanResponse') {
      return [{
        x: this.dataGraph.reliance.YearMonth,
        y: this.multiplied(this.dataGraph.reliance.meanResponse),
        type: "scatter",
        line: {
          width: 3,
        }, // this.labelsByName[this.categoryName].color,
        name: this.categoryName + " mean response time",
        lineshape: "spline",
        mode: "lines+markers",
      },
      {
        x: this.dataGraph.reliance.YearMonth,
        y: this.multiplied(this.dataGraph.reliance.rollingresponse),
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    } else if (this.keyToShow === 'meanResolve') {
      return [{
        x: this.dataGraph.reliance.YearMonth,
        y: this.multiplied(this.dataGraph.reliance.meanResolve),
        type: "scatter",
        line: {
          width: 3,
        }, // this.labelsByName[this.categoryName].color,
        name: this.categoryName + " mean customer workload",
        lineshape: "spline",
        mode: "lines+markers",
      },
      {
        x: this.dataGraph.reliance.YearMonth,
        y: this.multiplied(this.dataGraph.reliance.rollingresolve),
        type: "scatter",
        colorscale: "Viridis",
        name: this.categoryName + " 6 months rolling avg",
        lineshape: "spline",
        mode: "lines+markers",
      },
      ];
    }
  }

  get timeSeriesLayout() {

    if (this.keyToShow === 'main' || this.keyToShow === 'subcategorybreakdown') {
      if (this.showTimeline) {
      return {
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          rangebreaks: [
          {enabled: true,  bounds: ["2010-03", "2027-05"]},
        {enabled: true, bounds: ["2010-03", "2027-05"]},
    ],
          title: 'Time',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          type: 'linear',
          title: this.coolAxis,
        },
      };
     } else {
      return {
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Time',
          rangebreaks: [
        {enabled: true,  bounds: ["2010-03", "2027-05"]},
        {enabled: true, bounds: ["2010-03", "2027-05"]},
    ],
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
        },
        yaxis: {
          type: 'linear',
          title: this.coolAxis,
        },
      };
     }
   } else if (this.keyToShow === 'meanSpent') {
      return {
        title: 'Mean agent workload',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Time',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'Mean agent workload',
        },
      };
    } else if (this.keyToShow === 'countOfLabel') {
      return {
        title: 'Volume',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Time',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'Total volume',
        },
      };
    } else if (this.keyToShow === 'meanResponse') {
      return {
        title: 'Mean response time',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Time',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'Mean response time',
        },
      };
    } else if (this.keyToShow === 'meanResolve') {
      return {
        title: 'Mean customer workload',
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          title: 'Time',
          autorange: true,
          rangeselector: {
            buttons: [
              {
                count: 1,
                label: '1m',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 6,
                label: '6m',
                step: 'month',
                stepmode: 'backward',
              },
              { step: 'all' },
            ],
          },
          rangeslider: { range: [] },
          type: 'date',
        },
        yaxis: {
          title: 'Mean customer workload',
        },
      };
    }
  }

  public colorFallback(name) {
    // this.hexToRGBA(colors[this.snakeToCamel("grey")].base)
    return this.labelsByName[name] === undefined ?
    null :
    this.hexToRGBA(colors[this.snakeToCamel(this.labelsByName[name].color)].base);
  }

  public multiplied(array) {
    return array.map((x) => x / this.multiplier);
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.labels.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k.id);
      if (filteredLabels.length > 0) {
        mappedNames.push(filteredLabels[0].name);
      }
    });
    return mappedNames;
  }

  get coolAxis() {
    if (this.SubkeyToShow !== "countOfLabel" && this.SubkeyToShow !== "meanChangeVolume" && this.SubkeyToShow !== "meanChangeVolumeWeighted") {
      return this.subKeyTranslator[this.SubkeyToShow] + " (" + this.time + ")";
    } else {
      return this.subKeyTranslator[this.SubkeyToShow];
    }
  }

  @Emit()
  public focuslabel(clickedLabel) {
  }

}
