
import { Component, Vue } from "vue-property-decorator";

import { readModels, readModel, readConnectedDatasets } from "@/store/model/getters";
import { dispatchGetConnectedDatasets } from "@/store/model/actions";

import { readToken } from "@/store/main/getters";
import { api } from "@/api";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import LabelPieChart from "@/components/LabelPieChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import DatasetCard from "@/components/DatasetCard.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    LabelChart,
    LabelPieChart,
    ToolbarButton,
    RoundProgress,
    DatasetCard,
  },
})
export default class DashboardView extends Vue {
  public loading: boolean = false;
  public error: string = "";

  public async copyModelToWorkspace() {
    // NOTE: THIS ASSUMES THAT WE ONLY HAVE ONE DATASET CONNECTED!!!! SO DOES THE BACKEND
    this.error = "";
    this.loading = true;
    await api
      .copyModelToWorkspace(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.loading = false;
        this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/models`);
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        this.error = err.response;
      });
  }

  public async getConnectedDatasets() {
    await dispatchGetConnectedDatasets(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    });
  }

  public async mounted() {
    this.getConnectedDatasets();
  }

  get token() {
    return readToken(this.$store);
  }

  get connectedDatasets() {
    return readConnectedDatasets(this.$store);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get models() {
    return readModels(this.$store);
  }
}
