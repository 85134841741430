
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import { readModel, readFirstLabelContainer, readLabels, readLabel } from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchUpdateLabel,
  dispatchDeleteLabel,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetRecommendation,
} from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
  },
})
export default class CreateLabel extends Vue {
  public id: number = 1;
  public dialogLabel: boolean = true;
  public labelDescription: string = "";
  public labelName: string = "";
  public labelExamples: string[] = [];
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeLabelExample: string = "";
  public loading: boolean = false;
  public dialogRemove: boolean = false;
  public labelError: string = "";
  public redirected: boolean = false;

  public color: string = "green";
  public colors: string[] = [
    "red",
    "purple",
    "pink",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  get isAdmin() {
    return readHasAdminAccess;
  }

  public pushLabelExample() {
    if (this.activeLabelExample.replace(/ /g, "").length > 0) {
      this.labelExamples.push(this.activeLabelExample);
      this.activeLabelExample = "";
    }
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get label() {
    return readLabel(this.$store)(
      +this.$router.currentRoute.params.id,
      +this.$router.currentRoute.params.labelid,
    );
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public changeColor(color) {}

  public async deleteLabel() {
    this.labelError = "";
    this.loading = true;
    await dispatchDeleteLabel(this.$store, {
      labelContainerId: this.firstLabelContainer!.id,
      labelId: this.label!.id,
    })
      .then((r) => {
        this.pushRouter();
        this.loading = false;
        this.dialogRemove = false;
      })
      .catch((error) => {
        this.dialogRemove = false;
        this.loading = false;
        this.labelError = error.response;
      });
  }
  get remainingColors() {
    let remainingColors: string[] = [];
    if (this.labels && this.label) {
      this.colors.forEach((c) => {
        const colorExists = this.labels!.filter(
          (label) => label.color === c && label.name !== this.label!.name,
        );
        if (colorExists.length === 0) {
          remainingColors.push(c);
        }
      });
    } else {
      remainingColors = this.colors;
    }
    if (this.labels!.length && this.label) {
      this.color = this.label!.color;
      this.labelName = this.label!.name;
      this.labelDescription = this.label!.description ? this.label!.description : "";
    }

    return remainingColors;
  }

  get labelNameExists(): boolean {
    if (this.labels) {
      return (
        this.labels!.filter(
          (label) => label.name === this.labelName && this.label!.name !== label.name,
        ).length > 0
      );
    }
    return false;
  }

  get labelNameErrors(): string[] {
    if (this.labelNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelDescriptionErrors(): string[] {
    if (this.labelDescription.length > 250) {
      return ["Label description is too long"];
    }
    return [];
  }

  get labelIsClean(): boolean {
    if (
      this.labelName.length < 1 ||
      this.labelName.length > 40 ||
      this.labelDescription.length > 250
    ) {
      return false;
    }
    if (this.labelNameExists) {
      return false;
    }
    return true;
  }

  public async createLabelAPI() {
    this.labelError = "";
    if (this.activeLabelExample.length) {
      this.labelExamples.push(this.activeLabelExample);
    }
    this.activeLabelExample = "";
    const newLabel: ILabelCreate = {
      name: this.labelName,
      description: this.labelDescription,
      color: this.color,
      examples: this.labelExamples,
    };
    this.loading = true;
    await dispatchUpdateLabel(this.$store, {
      labelContainerId: this.firstLabelContainer!.id,
      labelId: this.label!.id,
      label: newLabel,
    })
      .then((r) => {
        this.dialogLabel = false;
        this.labelName = "";
        this.labelDescription = "";
        this.loading = false;
        this.labelExamples = [];
        this.pushRouter();
      })
      .catch((error) => {
        this.dialogRemove = false;
        this.loading = false;
        this.labelError = error.response;
      });
  }
  public created() {
    this.activeLabelId = +this.$router.currentRoute.params.labelid;
  }
  public mounted() {
    if (this.labels!.length) {
      this.labelName = this.label!.name;
      this.labelDescription = this.label!.description ? this.label!.description : "";
    }
    // redirected = we were redirected from dashboard
    if (this.$route.query.redirected === "true") {
      this.redirected = true;
    }
  }

  public pushRouter() {
    console.log("In push router", this.redirected);
    console.log("In push router", this.$router.currentRoute.params.workspaceid);
    console.log("In push router", this.$router.currentRoute.params.id);
    console.log("In push router", this.firstLabelContainer!.id);
    if (this.redirected === true) {
      this.$router.push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/dashboard/dashboard`,
      });
    } else {
      this.$router.push({
        path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${
          this.$router.currentRoute.params.id
        }/dashboard/labelcontainers/${this.firstLabelContainer!.id}`,
      });
    }
  }

  public removeLabelExample(toRemove) {
    let tmpExamples = this.labelExamples.slice();
    tmpExamples = tmpExamples.filter((example) => example !== toRemove);
    this.labelExamples = tmpExamples;
  }

  public addLabelExample() {
    if (this.activeLabelExample === "") {
      this.createLabelAPI();
    } else if (!this.labelExamples.includes(this.activeLabelExample)) {
      this.labelExamples.push(this.activeLabelExample);
      this.activeLabelExample = "";
    }
  }
}
