
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

import { readToken } from "@/store/main/getters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import colors from "vuetify/es5/util/colors";
import { api } from "@/api";
import { readModel, readFirstLabelContainer, readLabels, readAllLabelsByWorkspaceId } from "@/store/model/getters";

@Component
export default class TimeGraphTextCard extends Vue {
  @Prop({ default: {} })
  public item!: any;
  @Prop({ default: "csv" })
  public source: string;
  @Prop({ default: 1 })
  public multiplier: number;
  @Prop({ default: 1 })
  public totalMeanSpent: number;
  @Prop({ default: 1 })
  public time: number;

  public openPage(id: any) {
      window.open(`/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/browse?conversation_id=${id}`, '_blank');
  }

  public switchDateBucket(dateBucket: string) {
    this.$emit('conversationclicked', dateBucket);
  }

  public formatDate(date: string) {
    try {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    } catch (e) {
      console.log(e);
      return date
  }
}
}
