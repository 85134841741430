
import { IModel } from "@/interfaces";
import { dispatchGetOriginal } from "@/store/model/actions";
import { readLabelsByIds, readLabelsByName, readModel } from "@/store/model/getters";
import { Component, PropSync, Vue } from "vue-property-decorator";

import { dispatchGetDatasets } from "@/store/dataset/actions";

import { readDataset, readDatasets } from "@/store/dataset/getters";

import { api } from "@/api";
import { readToken, readUserProfile, readWorkspace } from "@/store/main/getters";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { ISubscription } from "@/interfaces";

import FileDrop from "@/components/FileDrop.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ModelCard from "@/components/ModelCard.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
    FileDrop,
  },
})
export default class DashboardView extends Vue {
  public metricThreshold: number = 0.0;
  public text: string = "";
  public modelPredictions: any[] = [];
  public inferenceError: any = null;
  public loading: boolean = false;
  public loadingButton: boolean = false;
  public columns: any[] = [];
  public rows: any[] = [];
  public rowsToShow: any[] = [];
  public connectedModels: IModel[] = [];
  public models: IModel[] = [];
  public priceDialog: boolean = false;
  public exportError: string = "";
  public refillDownloadError: string = "";
  public prepareExportError: string = "";
  public prepareColumnExportError: string = "";
  public originalDatasetError: string = "";
  public price: number = 0;
  public chosenModel: number = -1;
  public chosenColumnId: number = 1;
  public downloadProgress: number = 0;
  public datasetExports: any[] = [];
  public dialogRemove: boolean = false;
  public choosenDatasetExport: number = -1;
  public rowsLabeled: number = 0;
  public rowsUnlabeled: number = 0;
  public freeInferencesLeft: number = 0;
  public subscribedToPlan: ISubscription = {} as ISubscription;
  public rowsToPayFor: number = 0;
  public loadingPreview: boolean = false;

  public addAdditionalDataError: string = "";
  public loadingProgress: number | null = null;
  public file: any = null;
  public refillDialog: boolean = false;
  public refills: any[] = [];

  public selectedFrequency: string = "off";

  public headers = [
    {
      text: "Model name",
      sortable: true,
      value: "model_id",
      align: "left",
    },
    {
      text: "Model id",
      sortable: true,
      value: "model_id",
      align: "left",
    },
    {
      text: "Latest model version?",
      sortable: true,
      value: "checkpoint_name",
      align: "left",
    },
    {
      text: "Created at",
      sortable: true,
      value: "created_at",
      align: "left",
    },
    {
      text: "Status",
      sortable: true,
      value: "status",
      align: "left",
    },
  ];

  public refillheaders = [
    {
      text: "Refill ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Item count",
      sortable: true,
      value: "item_count",
      align: "left",
    },
    {
      text: "Uploaded at",
      sortable: true,
      value: "timestamp",
      align: "left",
    },
    {
      text: "Status",
      sortable: true,
      value: "status",
      align: "left",
    },
  ];

  public async setRefillFrequency() {
    this.loadingButton = true;
    await api
      .setRefillFrequency(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.selectedFrequency,
      )
      .then((r) => {
        dispatchGetDatasets(this.$store, {
          id: +this.$router.currentRoute.params.workspaceid,
        });
        this.loadingButton = false;
      })
      .catch((err) => {
        this.loadingButton = false;
        console.log("error", err);
      });
  }

  public logFiles(fileList: FileList) {
    this.addAdditionalDataError = "";
    this.file = fileList[0];
  }

  public onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.loadingProgress = percentCompleted;
  }

  public async refillDataset() {
    this.loadingButton = true;
    this.addAdditionalDataError = "";
    // this.dialog = false;

    const config = {
      onUploadProgress: this.onUploadProgress,
    };

    await api
      .refillDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.file,
        config,
      )
      .then((r) => {
        this.loadingButton = false;
        this.addAdditionalDataError = "";
        this.loadingProgress = null;
        this.file = null;
        this.refillDialog = false;
        dispatchGetDatasets(this.$store, {
          id: +this.$router.currentRoute.params.workspaceid,
        });
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loadingButton = false;
        this.loadingProgress = null;
        this.addAdditionalDataError = uploadError.response;
      });
  }

  public async triggerDatasetSetup() {
    this.loadingButton = true;
    await api
      .triggerDatasetSetup(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        dispatchGetDatasets(this.$store, {
          id: +this.$router.currentRoute.params.workspaceid,
        });
        this.loadingButton = false;
      })
      .catch((err) => {
        this.loadingButton = false;
        console.log("error", err);
      });
  }

  // free inferences left
  public async getSubscription() {
    await api
      .getSubscription(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.subscribedToPlan = r.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  // functions for table
  public parsedDate(timestamp) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(timestamp).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  public getModel(modelId) {
    return readModel(this.$store)(modelId);
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public getModelName(modelId) {
    try {
      const model = this.getModel(modelId);
      return model!.name;
    } catch (error) {
      return "Model unspecified";
    }
  }

  public isCurrentCheckpoint(modelId, checkpointName) {
    try {
      const model = this.getModel(modelId);
      const labelContainer = model!.label_containers[0];
      if (labelContainer!.checkpoint) {
        const checkpoint = labelContainer!.checkpoint;
        if (checkpoint === checkpointName) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  // ==================

  public async mounted() {
    await this.getSubscription();
    await this.chooseDataset();
    await this.getDatasetConnectedModels();
    await this.getModels();
    await this.getAllDatasetExport();
    await this.getAllRefill();
    this.selectedFrequency = this.dataset!.refill_frequency;
  }

  public async prepareColumnDatasetExport(modelId, columnId) {
    this.prepareColumnExportError = "";
    this.loading = true;
    this.priceDialog = false;
    await api
      .prepareColumnDatasetExport(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        modelId,
        columnId - 1,
      )
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.chosenModel = -1;
        this.prepareColumnExportError = error.response;
      });
    this.getAllDatasetExport();
  }

  public async prepareDatasetExportAllDeployed() {
    this.prepareExportError = "";
    this.loading = true;
    this.priceDialog = false;
    await api
      .prepareDatasetExportAllDeployed(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.metricThreshold,
      )
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.chosenModel = -1;
        this.prepareExportError = error.response;
      });
    this.getAllDatasetExport();
  }
  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );

      if (conversationColumn) {
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public async exportAllDeployedConversation() {
    this.prepareExportError = "";
    this.loading = true;
    this.priceDialog = false;
    await api
      .exportAllDeployedConversation(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((response) => {
        this.loading = false;
        this.getAllDatasetExport();
      })
      .catch((error) => {
        this.loading = false;
        this.chosenModel = -1;
        this.prepareExportError = error.response;
      });
  }

  public async prepareDatasetExport() {
    this.prepareExportError = "";
    this.loading = true;
    this.priceDialog = false;
    await api
      .prepareDatasetExport(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.chosenModel,
        this.metricThreshold,
      )
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.chosenModel = -1;
        this.prepareExportError = error.response;
      });
    this.getAllDatasetExport();
  }

  public async deleteDatasetExport() {
    this.dialogRemove = false;
    this.exportError = "";
    this.loading = true;
    await api
      .deleteDatasetExport(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.choosenDatasetExport,
      )
      .then((r) => {
        this.loading = false;
        this.choosenDatasetExport = -1;
      })
      .catch((error) => {
        this.loading = false;
        this.exportError = error.response;
        this.choosenDatasetExport = -1;
      });
    this.getAllDatasetExport();
  }

  public async downloadDatasetExport(datasetExportId) {
    this.exportError = "";
    const config = {
      onDownloadrogress: this.onDownloadProgress,
    };
    this.loading = true;
    await api
      .downloadDatasetExport(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        datasetExportId,
        config,
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        const modelId = this.getModelIdByDatasetExportId(datasetExportId);
        if (modelId != 1) {
          const modelName = this.getModelName(modelId);
          link.href = URL.createObjectURL(blob);
          link.download = `${this.dataset!.name}_${modelName}_${new Date().toISOString().slice(0, 10)}.csv`;
        } else {
          link.href = URL.createObjectURL(blob);
          link.download = `${this.dataset!.name}_${new Date().toISOString().slice(0, 10)}.csv`;
        }
        link.click();
        URL.revokeObjectURL(link.href);
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
        this.exportError = error.response;
        this.loading = false;
      });
  }

  public async downloadRefill(refillId: number, timestamp: string) {
    this.refillDownloadError = "";
    const config = {
      onDownloadrogress: this.onDownloadProgress,
    };
    this.loading = true;
    await api
      .downloadRefill(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        refillId,
        config,
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${this.dataset!.name}_${timestamp.slice(0, 10)}.csv`;
        link.click();
        URL.revokeObjectURL(link.href);
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting refill", error);
        this.refillDownloadError = error.response;
        if (error.response.data instanceof Blob) {
          error.response.data.text().then((text) => {
            const data = JSON.parse(text);
            this.refillDownloadError = data.detail;
          });
        }
        this.loading = false;
      });
  }

  public async getAllDatasetExport() {
    await api
      .getAllDatasetExport(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.datasetExports = r.data;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
      });
  }

  public getModelIdByDatasetExportId(datasetExportId: number): number {
    // Use the find method to search for the entry with the specified id
    const foundEntry = this.datasetExports.find((entry) => entry.id === datasetExportId);

    // If an entry is found, return its model_id; otherwise, return -1
    return foundEntry ? foundEntry.model_id : 1;
  }

  public async getAllRefill() {
    await api
      .getAllRefill(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.refills = r.data;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
      });
  }

  public statusCheck(model) {
    // returns true if status indicates training/uploading etc
    if (model?.status === "deployed" || model?.status === "ready") {
      if (model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public async getModels() {
    await api
      .getModels(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.models = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public async chooseDataset() {
    this.loadingPreview = true;
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        this.rowsToShow = this.rows;
        this.loadingPreview = false;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
        this.loadingPreview = false;
      });
  }

  get tableDataHeader() {
    const rows: any[] = [];
    this.columns.forEach((column, key) => {
      const text = "";
      rows.push({
        text: this.rows[0][column.value] + text,
        value: column.value,
        align: "left",
      });
    });
    return rows;
  }

  public async getExportPrice(modelId) {
    document.body.style.cursor = "wait";
    this.price = 0;
    this.rowsLabeled = 0;
    this.rowsUnlabeled = 0;
    this.chosenModel = modelId;
    this.exportError = "";
    this.originalDatasetError = "";
    await api
      .getExportPrice(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.dataset!.id,
        modelId,
        this.metricThreshold,
      )
      .then((r) => {
        this.price = r.data.price;
        this.rowsLabeled = r.data.rows_labeled;
        this.rowsUnlabeled = r.data.rows_unlabeled;
        this.freeInferencesLeft = r.data.free_inferences_left;
        this.rowsToPayFor = r.data.rows_to_pay_for;
        this.priceDialog = true;
        document.body.style.cursor = "default";
      })
      .catch((exportError) => {
        console.log("UploadError", exportError.response);
        this.priceDialog = false;
        this.price = 0;
        this.rowsLabeled = 0;
        this.rowsUnlabeled = 0;
        this.exportError = exportError.response;
        this.chosenModel = -1;
        document.body.style.cursor = "default";
      });
  }
  public onDownloadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.downloadProgress = percentCompleted;
  }

  public async downloadOriginal() {
    this.loading = true;
    this.exportError = "";
    this.originalDatasetError = "";

    const config = {
      onDownloadrogress: this.onDownloadProgress,
    };

    await dispatchGetOriginal(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: this.dataset!.id,
      datasetName: this.dataset!.name,
      config,
    })
      .then((r) => {
        this.downloadProgress = 0;
        this.loading = false;
        this.chosenModel = -1;
      })
      .catch((error) => {
        this.downloadProgress = 0;
        this.originalDatasetError = error.response;
        if (error.response.data instanceof Blob) {
          error.response.data.text().then((text) => {
            const data = JSON.parse(text);
            this.originalDatasetError = data.detail;
          });
        }
        this.loading = false;
        this.chosenModel = -1;
      });
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get previewRows() {
    return this.rows.slice(1);
  }

  get token() {
    return readToken(this.$store);
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.$router.currentRoute.params.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }
}
